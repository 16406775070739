export function maps() {
	var geojson = {
		"type": "FeatureCollection",
		"features": [{
				"type": "Feature",
				"properties": {
					"adcode": 511702,
					"name": "通川区",
					"center": [107.501062, 31.213522],
					"centroid": [107.438033, 31.359446],
					"childrenNum": 0,
					"level": "district",
					"parent": {
						"adcode": 511700
					},
					"subFeatureIndex": 0,
					"acroutes": [100000, 510000, 511700]
				},
				"geometry": {
					"type": "MultiPolygon",
					"coordinates": [
						[
							[
								[107.604291, 31.174507],
								[107.603727, 31.177718],
								[107.608673, 31.18307],
								[107.613525, 31.185393],
								[107.621142, 31.181414],
								[107.623229, 31.187675],
								[107.632256, 31.194259],
								[107.633215, 31.196783],
								[107.630902, 31.205243],
								[107.634551, 31.207727],
								[107.633084, 31.218488],
								[107.63677, 31.223273],
								[107.634682, 31.235849],
								[107.626765, 31.24148],
								[107.627254, 31.247878],
								[107.631504, 31.258897],
								[107.631071, 31.263317],
								[107.622759, 31.269048],
								[107.620032, 31.267716],
								[107.608635, 31.267131],
								[107.604498, 31.268886],
								[107.60211, 31.274617],
								[107.595828, 31.278793],
								[107.592989, 31.285653],
								[107.60211, 31.293661],
								[107.598142, 31.29812],
								[107.595283, 31.304635],
								[107.583473, 31.306027],
								[107.581611, 31.31474],
								[107.58336, 31.323936],
								[107.588193, 31.329885],
								[107.585146, 31.335087],
								[107.578602, 31.336741],
								[107.574972, 31.333635],
								[107.563952, 31.3342],
								[107.556222, 31.332204],
								[107.552931, 31.333978],
								[107.556674, 31.34664],
								[107.552085, 31.354281],
								[107.557313, 31.362526],
								[107.554304, 31.366296],
								[107.555752, 31.372947],
								[107.563218, 31.375588],
								[107.563444, 31.380667],
								[107.566716, 31.386854],
								[107.572132, 31.389856],
								[107.569443, 31.39699],
								[107.564516, 31.395781],
								[107.564516, 31.400939],
								[107.571437, 31.402189],
								[107.570534, 31.404304],
								[107.55579, 31.407246],
								[107.553947, 31.412766],
								[107.560266, 31.411034],
								[107.560755, 31.415023],
								[107.555978, 31.416332],
								[107.549245, 31.414076],
								[107.54571, 31.40912],
								[107.541083, 31.410047],
								[107.537247, 31.40505],
								[107.532545, 31.404002],
								[107.528408, 31.406964],
								[107.518497, 31.407407],
								[107.512817, 31.409966],
								[107.503922, 31.417038],
								[107.497848, 31.426586],
								[107.499785, 31.433637],
								[107.508041, 31.440183],
								[107.515732, 31.444493],
								[107.516221, 31.456174],
								[107.523161, 31.461369],
								[107.529555, 31.461591],
								[107.529574, 31.467571],
								[107.525831, 31.472242],
								[107.534557, 31.483859],
								[107.534313, 31.485691],
								[107.522653, 31.490341],
								[107.5238, 31.494065],
								[107.529781, 31.500547],
								[107.531379, 31.505539],
								[107.526715, 31.515501],
								[107.52066, 31.516407],
								[107.506348, 31.506786],
								[107.496851, 31.506243],
								[107.482784, 31.519103],
								[107.474415, 31.520492],
								[107.466516, 31.529225],
								[107.454255, 31.526187],
								[107.444701, 31.526187],
								[107.43259, 31.533652],
								[107.430051, 31.529447],
								[107.430916, 31.525201],
								[107.421306, 31.522927],
								[107.416266, 31.523168],
								[107.409101, 31.518198],
								[107.402387, 31.520633],
								[107.404098, 31.526328],
								[107.396312, 31.529829],
								[107.390652, 31.523511],
								[107.384013, 31.522263],
								[107.38202, 31.525483],
								[107.379894, 31.536348],
								[107.387718, 31.544859],
								[107.381474, 31.554817],
								[107.375908, 31.554153],
								[107.374535, 31.547937],
								[107.365, 31.547796],
								[107.361445, 31.541238],
								[107.354525, 31.549104],
								[107.35161, 31.54846],
								[107.348037, 31.542988],
								[107.351591, 31.538843],
								[107.350669, 31.533672],
								[107.344351, 31.537435],
								[107.33696, 31.53496],
								[107.337825, 31.531177],
								[107.331619, 31.531801],
								[107.32955, 31.525422],
								[107.332183, 31.516105],
								[107.328027, 31.505961],
								[107.327387, 31.499138],
								[107.322874, 31.492898],
								[107.311214, 31.490039],
								[107.285807, 31.491308],
								[107.283399, 31.48402],
								[107.284904, 31.47794],
								[107.277099, 31.465477],
								[107.273677, 31.461711],
								[107.273169, 31.453697],
								[107.265176, 31.44691],
								[107.258688, 31.445218],
								[107.251636, 31.453656],
								[107.250338, 31.457342],
								[107.24101, 31.465296],
								[107.233995, 31.463524],
								[107.236421, 31.456919],
								[107.234221, 31.451985],
								[107.225438, 31.450052],
								[107.226868, 31.444976],
								[107.225025, 31.434583],
								[107.218668, 31.429809],
								[107.210036, 31.426425],
								[107.196364, 31.42985],
								[107.196289, 31.413129],
								[107.197285, 31.407105],
								[107.189537, 31.409402],
								[107.187055, 31.405856],
								[107.189236, 31.395519],
								[107.218837, 31.385322],
								[107.221207, 31.383367],
								[107.228823, 31.384899],
								[107.227545, 31.373129],
								[107.229369, 31.370629],
								[107.239731, 31.371456],
								[107.247254, 31.373653],
								[107.257183, 31.378853],
								[107.266267, 31.37978],
								[107.281481, 31.378772],
								[107.29252, 31.382198],
								[107.292295, 31.38776],
								[107.28355, 31.38641],
								[107.276065, 31.387499],
								[107.265383, 31.392154],
								[107.26448, 31.399932],
								[107.273846, 31.401101],
								[107.276159, 31.39562],
								[107.280259, 31.395559],
								[107.285054, 31.400959],
								[107.291731, 31.398803],
								[107.293574, 31.405997],
								[107.309879, 31.408052],
								[107.321896, 31.418649],
								[107.327557, 31.420684],
								[107.326409, 31.411235],
								[107.327594, 31.40513],
								[107.334647, 31.39431],
								[107.341868, 31.390723],
								[107.34499, 31.37978],
								[107.350651, 31.372907],
								[107.345799, 31.362325],
								[107.332822, 31.348052],
								[107.330359, 31.34408],
								[107.330641, 31.337769],
								[107.332916, 31.334785],
								[107.326992, 31.330469],
								[107.328403, 31.324319],
								[107.324566, 31.317341],
								[107.322592, 31.307983],
								[107.322272, 31.299713],
								[107.328046, 31.291523],
								[107.329569, 31.28646],
								[107.335474, 31.284624],
								[107.338897, 31.279681],
								[107.336678, 31.271973],
								[107.345987, 31.270238],
								[107.349955, 31.271953],
								[107.354393, 31.278168],
								[107.357553, 31.285975],
								[107.36421, 31.291342],
								[107.375945, 31.297958],
								[107.380177, 31.298705],
								[107.386571, 31.2935],
								[107.396839, 31.288981],
								[107.403534, 31.283514],
								[107.408047, 31.274516],
								[107.407107, 31.26709],
								[107.393868, 31.235425],
								[107.383524, 31.216166],
								[107.377826, 31.199509],
								[107.37824, 31.197207],
								[107.388169, 31.192118],
								[107.401258, 31.194178],
								[107.406035, 31.189897],
								[107.409759, 31.179132],
								[107.417169, 31.178991],
								[107.423788, 31.189412],
								[107.432402, 31.190644],
								[107.439285, 31.18715],
								[107.444551, 31.179576],
								[107.443516, 31.173881],
								[107.433774, 31.163054],
								[107.437254, 31.160064],
								[107.440808, 31.16063],
								[107.447259, 31.170851],
								[107.453408, 31.177557],
								[107.452318, 31.184626],
								[107.465557, 31.184726],
								[107.474509, 31.189634],
								[107.483122, 31.199206],
								[107.483348, 31.206495],
								[107.481731, 31.212876],
								[107.489272, 31.213542],
								[107.49813, 31.206717],
								[107.502549, 31.206132],
								[107.511256, 31.210513],
								[107.520998, 31.218387],
								[107.522841, 31.225938],
								[107.529348, 31.232781],
								[107.53751, 31.230419],
								[107.541967, 31.234719],
								[107.549264, 31.23268],
								[107.552405, 31.222526],
								[107.547609, 31.202841],
								[107.55011, 31.191573],
								[107.546142, 31.187089],
								[107.544073, 31.181394],
								[107.545352, 31.1738],
								[107.554172, 31.166993],
								[107.563293, 31.165639],
								[107.576646, 31.167397],
								[107.582513, 31.171598],
								[107.586858, 31.178768],
								[107.590149, 31.180485],
								[107.599853, 31.177597],
								[107.604291, 31.174507]
							]
						],
						[
							[
								[107.438702, 31.136365],
								[107.43353, 31.122139],
								[107.434583, 31.115308],
								[107.44109, 31.118703],
								[107.443065, 31.126807],
								[107.439943, 31.130687],
								[107.4403, 31.136102],
								[107.438702, 31.136365]
							]
						]
					]
				}
			}, {
				"type": "Feature",
				"properties": {
					"adcode": 511703,
					"name": "达川区",
					"center": [107.507926, 31.199062],
					"centroid": [107.418636, 31.109789],
					"childrenNum": 0,
					"level": "district",
					"parent": {
						"adcode": 511700
					},
					"subFeatureIndex": 1,
					"acroutes": [100000, 510000, 511700]
				},
				"geometry": {
					"type": "MultiPolygon",
					"coordinates": [
						[
							[
								[107.437254, 31.160064],
								[107.433774, 31.163054],
								[107.443516, 31.173881],
								[107.444551, 31.179576],
								[107.439285, 31.18715],
								[107.432402, 31.190644],
								[107.423788, 31.189412],
								[107.417169, 31.178991],
								[107.409759, 31.179132],
								[107.406035, 31.189897],
								[107.401258, 31.194178],
								[107.388169, 31.192118],
								[107.37824, 31.197207],
								[107.377826, 31.199509],
								[107.383524, 31.216166],
								[107.393868, 31.235425],
								[107.407107, 31.26709],
								[107.408047, 31.274516],
								[107.403534, 31.283514],
								[107.396839, 31.288981],
								[107.386571, 31.2935],
								[107.380177, 31.298705],
								[107.375945, 31.297958],
								[107.36421, 31.291342],
								[107.357553, 31.285975],
								[107.354393, 31.278168],
								[107.349955, 31.271953],
								[107.345987, 31.270238],
								[107.336678, 31.271973],
								[107.338897, 31.279681],
								[107.335474, 31.284624],
								[107.329569, 31.28646],
								[107.328046, 31.291523],
								[107.322272, 31.299713],
								[107.322592, 31.307983],
								[107.324566, 31.317341],
								[107.328403, 31.324319],
								[107.326992, 31.330469],
								[107.332916, 31.334785],
								[107.330641, 31.337769],
								[107.330359, 31.34408],
								[107.332822, 31.348052],
								[107.345799, 31.362325],
								[107.350651, 31.372907],
								[107.34499, 31.37978],
								[107.341868, 31.390723],
								[107.334647, 31.39431],
								[107.327594, 31.40513],
								[107.326409, 31.411235],
								[107.327557, 31.420684],
								[107.321896, 31.418649],
								[107.309879, 31.408052],
								[107.293574, 31.405997],
								[107.291731, 31.398803],
								[107.285054, 31.400959],
								[107.280259, 31.395559],
								[107.276159, 31.39562],
								[107.273846, 31.401101],
								[107.26448, 31.399932],
								[107.265383, 31.392154],
								[107.276065, 31.387499],
								[107.28355, 31.38641],
								[107.292295, 31.38776],
								[107.29252, 31.382198],
								[107.281481, 31.378772],
								[107.266267, 31.37978],
								[107.257183, 31.378853],
								[107.247254, 31.373653],
								[107.239731, 31.371456],
								[107.229369, 31.370629],
								[107.227545, 31.373129],
								[107.228823, 31.384899],
								[107.221207, 31.383367],
								[107.218837, 31.385322],
								[107.189236, 31.395519],
								[107.187055, 31.405856],
								[107.189537, 31.409402],
								[107.197285, 31.407105],
								[107.196289, 31.413129],
								[107.196364, 31.42985],
								[107.182748, 31.428903],
								[107.177219, 31.42983],
								[107.173984, 31.427916],
								[107.172235, 31.4202],
								[107.162757, 31.410268],
								[107.16114, 31.401262],
								[107.158112, 31.398965],
								[107.153147, 31.389091],
								[107.145455, 31.379699],
								[107.145173, 31.376837],
								[107.150702, 31.371254],
								[107.149499, 31.366336],
								[107.149875, 31.356539],
								[107.146884, 31.350209],
								[107.139945, 31.346439],
								[107.13372, 31.345451],
								[107.129771, 31.33789],
								[107.120349, 31.333998],
								[107.116493, 31.334018],
								[107.108407, 31.337587],
								[107.101975, 31.33539],
								[107.09622, 31.34408],
								[107.093926, 31.350048],
								[107.087344, 31.351459],
								[107.086234, 31.358656],
								[107.082943, 31.360611],
								[107.082605, 31.367969],
								[107.074819, 31.373411],
								[107.072637, 31.372242],
								[107.069685, 31.359281],
								[107.066187, 31.35916],
								[107.062482, 31.363272],
								[107.055956, 31.360369],
								[107.052326, 31.35412],
								[107.046534, 31.34789],
								[107.047719, 31.342588],
								[107.05385, 31.339261],
								[107.054621, 31.326799],
								[107.052646, 31.324541],
								[107.04629, 31.32686],
								[107.040554, 31.322343],
								[107.035777, 31.321697],
								[107.033859, 31.316252],
								[107.040046, 31.308205],
								[107.038711, 31.29465],
								[107.029552, 31.296728],
								[107.019058, 31.304494],
								[107.014845, 31.302234],
								[107.012532, 31.297192],
								[107.014601, 31.292734],
								[107.011592, 31.28646],
								[107.001512, 31.281275],
								[106.999123, 31.278591],
								[107.003486, 31.269209],
								[106.994798, 31.26142],
								[107.004765, 31.255568],
								[107.011874, 31.255346],
								[107.016783, 31.248787],
								[107.013585, 31.245013],
								[107.02233, 31.229369],
								[107.024644, 31.213098],
								[107.029383, 31.204375],
								[107.04014, 31.200377],
								[107.043281, 31.197712],
								[107.056407, 31.19753],
								[107.062294, 31.203608],
								[107.065209, 31.203184],
								[107.075947, 31.194137],
								[107.086761, 31.181818],
								[107.09355, 31.179071],
								[107.114632, 31.184908],
								[107.115685, 31.194985],
								[107.118731, 31.199004],
								[107.122812, 31.199347],
								[107.12443, 31.195531],
								[107.128868, 31.195329],
								[107.134341, 31.202497],
								[107.139757, 31.206091],
								[107.143687, 31.215197],
								[107.158413, 31.216772],
								[107.160933, 31.213037],
								[107.16082, 31.203628],
								[107.173552, 31.210008],
								[107.177106, 31.206899],
								[107.181526, 31.207],
								[107.184008, 31.204113],
								[107.18854, 31.205001],
								[107.190383, 31.202255],
								[107.197417, 31.200983],
								[107.201347, 31.205728],
								[107.205692, 31.201528],
								[107.211898, 31.207343],
								[107.226435, 31.206616],
								[107.227808, 31.197247],
								[107.222298, 31.194501],
								[107.215339, 31.187433],
								[107.209265, 31.187917],
								[107.195029, 31.185211],
								[107.189537, 31.185393],
								[107.19123, 31.181192],
								[107.194991, 31.181818],
								[107.201291, 31.17794],
								[107.200915, 31.162205],
								[107.198244, 31.151296],
								[107.194145, 31.149438],
								[107.190496, 31.143215],
								[107.184121, 31.13879],
								[107.186528, 31.130707],
								[107.183726, 31.126281],
								[107.195894, 31.123796],
								[107.196458, 31.110821],
								[107.193392, 31.103484],
								[107.184948, 31.087756],
								[107.187468, 31.084319],
								[107.197267, 31.084077],
								[107.216298, 31.088707],
								[107.226191, 31.081206],
								[107.226567, 31.071299],
								[107.233205, 31.066425],
								[107.235293, 31.058761],
								[107.229989, 31.051056],
								[107.230196, 31.044746],
								[107.234221, 31.041287],
								[107.235424, 31.035219],
								[107.242251, 31.031376],
								[107.248627, 31.02559],
								[107.253836, 31.024073],
								[107.259403, 31.019764],
								[107.266286, 31.017478],
								[107.267113, 31.01509],
								[107.261358, 31.005823],
								[107.265703, 31.002788],
								[107.260794, 30.99095],
								[107.265778, 30.993298],
								[107.270762, 30.992751],
								[107.270592, 30.988299],
								[107.267339, 30.984292],
								[107.260268, 30.979617],
								[107.264123, 30.971419],
								[107.260757, 30.965266],
								[107.261001, 30.959112],
								[107.269407, 30.958201],
								[107.269276, 30.953848],
								[107.276366, 30.958282],
								[107.281049, 30.956318],
								[107.291956, 30.957168],
								[107.296902, 30.955184],
								[107.30324, 30.956986],
								[107.30119, 30.952208],
								[107.314862, 30.946256],
								[107.312267, 30.939271],
								[107.316574, 30.941943],
								[107.320184, 30.935444],
								[107.325619, 30.937266],
								[107.326485, 30.93273],
								[107.332578, 30.9368],
								[107.335831, 30.933317],
								[107.346081, 30.936294],
								[107.349203, 30.932325],
								[107.350105, 30.936881],
								[107.354318, 30.934755],
								[107.36169, 30.938116],
								[107.365771, 30.944616],
								[107.369795, 30.937995],
								[107.375813, 30.938744],
								[107.382283, 30.931961],
								[107.390426, 30.932001],
								[107.392965, 30.926594],
								[107.392946, 30.918575],
								[107.402199, 30.915922],
								[107.409778, 30.915253],
								[107.416492, 30.912175],
								[107.420196, 30.914079],
								[107.429242, 30.913025],
								[107.43558, 30.905369],
								[107.435467, 30.901521],
								[107.441053, 30.896254],
								[107.446431, 30.895362],
								[107.453747, 30.902007],
								[107.45952, 30.90144],
								[107.463413, 30.904478],
								[107.464391, 30.909076],
								[107.471556, 30.920985],
								[107.477612, 30.924691],
								[107.494274, 30.915273],
								[107.506216, 30.911709],
								[107.516579, 30.920944],
								[107.521355, 30.916023],
								[107.534031, 30.90782],
								[107.543735, 30.896537],
								[107.544073, 30.892972],
								[107.540876, 30.883471],
								[107.52664, 30.86564],
								[107.513062, 30.8549],
								[107.516974, 30.853785],
								[107.523274, 30.848232],
								[107.525079, 30.841199],
								[107.531172, 30.839679],
								[107.531831, 30.844381],
								[107.536288, 30.848212],
								[107.545522, 30.846226],
								[107.552348, 30.848029],
								[107.556523, 30.846286],
								[107.560679, 30.849874],
								[107.571907, 30.846874],
								[107.577624, 30.848131],
								[107.579354, 30.843834],
								[107.584056, 30.840469],
								[107.586989, 30.844726],
								[107.598254, 30.844746],
								[107.614672, 30.839172],
								[107.616722, 30.834591],
								[107.616158, 30.828571],
								[107.623605, 30.821435],
								[107.629999, 30.817968],
								[107.635434, 30.818029],
								[107.647452, 30.823239],
								[107.654147, 30.83305],
								[107.666615, 30.846388],
								[107.670452, 30.852002],
								[107.688825, 30.870645],
								[107.695803, 30.875934],
								[107.704134, 30.872226],
								[107.706184, 30.873664],
								[107.715512, 30.887502],
								[107.724595, 30.904134],
								[107.729917, 30.909359],
								[107.743514, 30.927951],
								[107.754723, 30.937995],
								[107.759556, 30.944697],
								[107.768376, 30.951905],
								[107.7721, 30.959132],
								[107.785904, 30.966521],
								[107.79604, 30.968302],
								[107.801061, 30.970933],
								[107.802378, 30.974415],
								[107.797469, 30.977411],
								[107.801757, 30.980669],
								[107.797601, 30.984393],
								[107.790662, 30.986801],
								[107.799707, 30.99006],
								[107.802829, 30.997729],
								[107.803826, 31.004528],
								[107.815618, 31.009304],
								[107.820281, 31.012986],
								[107.834386, 31.017983],
								[107.835345, 31.021018],
								[107.830963, 31.026864],
								[107.818927, 31.0335],
								[107.813455, 31.03797],
								[107.798316, 31.039851],
								[107.795326, 31.041813],
								[107.789477, 31.054656],
								[107.777591, 31.055708],
								[107.774657, 31.070308],
								[107.782744, 31.082924],
								[107.780638, 31.093397],
								[107.783384, 31.100169],
								[107.779509, 31.103625],
								[107.76958, 31.105727],
								[107.762979, 31.112458],
								[107.764784, 31.122886],
								[107.761643, 31.125453],
								[107.742235, 31.12422],
								[107.732776, 31.125311],
								[107.728507, 31.1146],
								[107.725197, 31.112397],
								[107.721849, 31.115409],
								[107.716997, 31.124402],
								[107.701727, 31.132708],
								[107.695652, 31.126019],
								[107.689559, 31.112114],
								[107.686174, 31.107405],
								[107.680626, 31.11072],
								[107.671806, 31.113569],
								[107.661218, 31.119228],
								[107.656855, 31.127171],
								[107.653789, 31.128767],
								[107.649802, 31.124382],
								[107.643389, 31.127939],
								[107.638838, 31.128383],
								[107.63393, 31.137153],
								[107.626671, 31.144811],
								[107.617474, 31.152609],
								[107.613243, 31.154347],
								[107.610554, 31.164609],
								[107.603407, 31.165639],
								[107.600944, 31.167861],
								[107.60572, 31.171639],
								[107.604291, 31.174507],
								[107.599853, 31.177597],
								[107.590149, 31.180485],
								[107.586858, 31.178768],
								[107.582513, 31.171598],
								[107.576646, 31.167397],
								[107.563293, 31.165639],
								[107.554172, 31.166993],
								[107.545352, 31.1738],
								[107.544073, 31.181394],
								[107.546142, 31.187089],
								[107.55011, 31.191573],
								[107.547609, 31.202841],
								[107.552405, 31.222526],
								[107.549264, 31.23268],
								[107.541967, 31.234719],
								[107.53751, 31.230419],
								[107.529348, 31.232781],
								[107.522841, 31.225938],
								[107.520998, 31.218387],
								[107.511256, 31.210513],
								[107.502549, 31.206132],
								[107.49813, 31.206717],
								[107.489272, 31.213542],
								[107.481731, 31.212876],
								[107.483348, 31.206495],
								[107.483122, 31.199206],
								[107.474509, 31.189634],
								[107.465557, 31.184726],
								[107.452318, 31.184626],
								[107.453408, 31.177557],
								[107.447259, 31.170851],
								[107.440808, 31.16063],
								[107.4403, 31.136102],
								[107.439943, 31.130687],
								[107.443065, 31.126807],
								[107.44109, 31.118703],
								[107.434583, 31.115308],
								[107.43353, 31.122139],
								[107.438702, 31.136365],
								[107.437254, 31.160064]
							]
						],
						[
							[
								[107.438702, 31.136365],
								[107.4403, 31.136102],
								[107.440808, 31.16063],
								[107.437254, 31.160064],
								[107.438702, 31.136365]
							]
						]
					]
				}
			}, {
				"type": "Feature",
				"properties": {
					"adcode": 511722,
					"name": "宣汉县",
					"center": [107.722254, 31.355025],
					"centroid": [107.929105, 31.513846],
					"childrenNum": 0,
					"level": "district",
					"parent": {
						"adcode": 511700
					},
					"subFeatureIndex": 2,
					"acroutes": [100000, 510000, 511700]
				},
				"geometry": {
					"type": "MultiPolygon",
					"coordinates": [
						[
							[
								[108.019929, 31.24255],
								[108.019478, 31.245013],
								[108.028636, 31.247737],
								[108.035933, 31.251814],
								[108.045092, 31.254216],
								[108.059949, 31.254982],
								[108.066418, 31.261743],
								[108.078191, 31.261097],
								[108.090152, 31.264709],
								[108.095361, 31.268987],
								[108.094496, 31.27504],
								[108.11193, 31.286581],
								[108.117515, 31.287166],
								[108.123345, 31.291503],
								[108.146533, 31.302759],
								[108.152739, 31.309516],
								[108.161409, 31.313772],
								[108.162857, 31.319701],
								[108.165678, 31.321435],
								[108.176849, 31.323533],
								[108.18046, 31.325791],
								[108.184842, 31.333736],
								[108.185519, 31.338031],
								[108.178071, 31.344725],
								[108.180591, 31.349241],
								[108.178316, 31.35293],
								[108.171169, 31.356983],
								[108.158889, 31.360289],
								[108.157685, 31.367263],
								[108.154131, 31.37077],
								[108.157817, 31.376636],
								[108.164192, 31.379195],
								[108.167314, 31.377402],
								[108.17525, 31.382621],
								[108.178316, 31.390723],
								[108.182848, 31.39302],
								[108.203422, 31.395378],
								[108.208048, 31.399126],
								[108.21401, 31.409583],
								[108.216323, 31.411175],
								[108.216568, 31.42703],
								[108.210343, 31.437141],
								[108.213277, 31.440948],
								[108.223865, 31.449246],
								[108.225576, 31.455288],
								[108.223131, 31.465175],
								[108.210813, 31.467873],
								[108.206826, 31.464289],
								[108.193304, 31.468195],
								[108.194169, 31.475987],
								[108.189675, 31.480638],
								[108.188998, 31.488369],
								[108.190502, 31.491489],
								[108.208124, 31.492737],
								[108.222022, 31.498393],
								[108.226742, 31.505841],
								[108.236653, 31.506364],
								[108.247673, 31.50101],
								[108.249197, 31.498554],
								[108.254801, 31.498836],
								[108.259578, 31.502499],
								[108.277011, 31.501171],
								[108.281732, 31.50729],
								[108.295987, 31.503506],
								[108.311427, 31.506686],
								[108.322165, 31.502036],
								[108.334671, 31.509161],
								[108.339053, 31.508497],
								[108.345334, 31.514917],
								[108.347121, 31.52341],
								[108.340783, 31.530111],
								[108.339072, 31.537898],
								[108.350337, 31.546026],
								[108.353835, 31.544235],
								[108.359815, 31.545161],
								[108.381142, 31.542606],
								[108.388645, 31.546388],
								[108.391165, 31.5553],
								[108.390714, 31.562904],
								[108.377926, 31.570426],
								[108.39361, 31.57634],
								[108.389247, 31.582916],
								[108.38889, 31.588808],
								[108.399722, 31.601657],
								[108.417889, 31.610081],
								[108.421519, 31.614484],
								[108.423098, 31.621279],
								[108.434119, 31.629862],
								[108.442657, 31.633701],
								[108.450894, 31.632274],
								[108.456461, 31.628877],
								[108.460316, 31.621279],
								[108.465281, 31.618364],
								[108.468271, 31.622284],
								[108.467839, 31.634304],
								[108.470584, 31.638063],
								[108.476546, 31.640032],
								[108.48136, 31.639188],
								[108.490162, 31.641279],
								[108.496236, 31.650784],
								[108.502348, 31.652292],
								[108.506185, 31.657155],
								[108.513275, 31.65619],
								[108.519048, 31.665714],
								[108.527417, 31.665473],
								[108.532796, 31.66913],
								[108.541446, 31.664589],
								[108.546468, 31.666699],
								[108.542838, 31.673611],
								[108.53225, 31.677167],
								[108.528, 31.680522],
								[108.527549, 31.684519],
								[108.514798, 31.694101],
								[108.516114, 31.696391],
								[108.524784, 31.699725],
								[108.525405, 31.702477],
								[108.52296, 31.713523],
								[108.518653, 31.726174],
								[108.506185, 31.731715],
								[108.506109, 31.734828],
								[108.535278, 31.757693],
								[108.528056, 31.758075],
								[108.525424, 31.761025],
								[108.515719, 31.761085],
								[108.48924, 31.774713],
								[108.488563, 31.780312],
								[108.478239, 31.777904],
								[108.468403, 31.781356],
								[108.462554, 31.780573],
								[108.45537, 31.78559],
								[108.44781, 31.7826],
								[108.422008, 31.784105],
								[108.41204, 31.776219],
								[108.410893, 31.76797],
								[108.408693, 31.767528],
								[108.394588, 31.773208],
								[108.386802, 31.771944],
								[108.375707, 31.76512],
								[108.363388, 31.769696],
								[108.357088, 31.770519],
								[108.348155, 31.762731],
								[108.337304, 31.747034],
								[108.327826, 31.736052],
								[108.322598, 31.731876],
								[108.303942, 31.719808],
								[108.290382, 31.716173],
								[108.281844, 31.712498],
								[108.274284, 31.713844],
								[108.268229, 31.721194],
								[108.261383, 31.727358],
								[108.248764, 31.731936],
								[108.239116, 31.729567],
								[108.232515, 31.722338],
								[108.229939, 31.715651],
								[108.22347, 31.705489],
								[108.218919, 31.7033],
								[108.18802, 31.720531],
								[108.178128, 31.730149],
								[108.170737, 31.732478],
								[108.159585, 31.733703],
								[108.154695, 31.731615],
								[108.143693, 31.733784],
								[108.130454, 31.733041],
								[108.111516, 31.742115],
								[108.101172, 31.749082],
								[108.100288, 31.753618],
								[108.108488, 31.754542],
								[108.115559, 31.760985],
								[108.112776, 31.76805],
								[108.107623, 31.768753],
								[108.096264, 31.77377],
								[108.093274, 31.778928],
								[108.094158, 31.782881],
								[108.091844, 31.789242],
								[108.094346, 31.795362],
								[108.087143, 31.802244],
								[108.08009, 31.814121],
								[108.075596, 31.815545],
								[108.06892, 31.807841],
								[108.0519, 31.812094],
								[108.049342, 31.808022],
								[108.044622, 31.805735],
								[108.031119, 31.805354],
								[108.021603, 31.807761],
								[108.015942, 31.805976],
								[108.00494, 31.795563],
								[108.002345, 31.797348],
								[108.000634, 31.806618],
								[107.995575, 31.807019],
								[107.987789, 31.803648],
								[107.982542, 31.80417],
								[107.978612, 31.796586],
								[107.969886, 31.795262],
								[107.952603, 31.800799],
								[107.945193, 31.807681],
								[107.933213, 31.811111],
								[107.923998, 31.810409],
								[107.914087, 31.802565],
								[107.909781, 31.789182],
								[107.905004, 31.783383],
								[107.898666, 31.78266],
								[107.8953, 31.77951],
								[107.894848, 31.774192],
								[107.886348, 31.757934],
								[107.884166, 31.751651],
								[107.877171, 31.744545],
								[107.875064, 31.740289],
								[107.87356, 31.730671],
								[107.877453, 31.723101],
								[107.891557, 31.712498],
								[107.893814, 31.708643],
								[107.883301, 31.705329],
								[107.876794, 31.706232],
								[107.860771, 31.716555],
								[107.85543, 31.716937],
								[107.849318, 31.710972],
								[107.847833, 31.705811],
								[107.846968, 31.69384],
								[107.849939, 31.689903],
								[107.858327, 31.687271],
								[107.867147, 31.689481],
								[107.873579, 31.686408],
								[107.878205, 31.675097],
								[107.883884, 31.67128],
								[107.879258, 31.669251],
								[107.868256, 31.659807],
								[107.870344, 31.654904],
								[107.854829, 31.650141],
								[107.842116, 31.645177],
								[107.834104, 31.645238],
								[107.829008, 31.648172],
								[107.829948, 31.655306],
								[107.821636, 31.660189],
								[107.813981, 31.659224],
								[107.801118, 31.663143],
								[107.799143, 31.659626],
								[107.786186, 31.652493],
								[107.789195, 31.646323],
								[107.787164, 31.641841],
								[107.782067, 31.6416],
								[107.774714, 31.650061],
								[107.767041, 31.65203],
								[107.758503, 31.659345],
								[107.748611, 31.672405],
								[107.73759, 31.677609],
								[107.734318, 31.697054],
								[107.731666, 31.705449],
								[107.732607, 31.717238],
								[107.724068, 31.720752],
								[107.717392, 31.729186],
								[107.70545, 31.728724],
								[107.703118, 31.729728],
								[107.697796, 31.740349],
								[107.684462, 31.747476],
								[107.679234, 31.747375],
								[107.670677, 31.750246],
								[107.660108, 31.757753],
								[107.656008, 31.762772],
								[107.651062, 31.761908],
								[107.649407, 31.753839],
								[107.639252, 31.746472],
								[107.616515, 31.733743],
								[107.60258, 31.73288],
								[107.598706, 31.729607],
								[107.595603, 31.723122],
								[107.587648, 31.721977],
								[107.581836, 31.714748],
								[107.589453, 31.713041],
								[107.592857, 31.708502],
								[107.590788, 31.703541],
								[107.576289, 31.697496],
								[107.577097, 31.693619],
								[107.569105, 31.689782],
								[107.564083, 31.690043],
								[107.551163, 31.694463],
								[107.54003, 31.692313],
								[107.532902, 31.684539],
								[107.531191, 31.68251],
								[107.531417, 31.674937],
								[107.529216, 31.664167],
								[107.531342, 31.658963],
								[107.525436, 31.653959],
								[107.518121, 31.63744],
								[107.510072, 31.634988],
								[107.507721, 31.630606],
								[107.499991, 31.626525],
								[107.495798, 31.618565],
								[107.497189, 31.606503],
								[107.494763, 31.600451],
								[107.489855, 31.597012],
								[107.480019, 31.5984],
								[107.471669, 31.596228],
								[107.462304, 31.586455],
								[107.454085, 31.583057],
								[107.445453, 31.591603],
								[107.439981, 31.590377],
								[107.43605, 31.584605],
								[107.43605, 31.578753],
								[107.433191, 31.575575],
								[107.433737, 31.570386],
								[107.425312, 31.572699],
								[107.423788, 31.570205],
								[107.407559, 31.566343],
								[107.409402, 31.562763],
								[107.405621, 31.558116],
								[107.39902, 31.559504],
								[107.398795, 31.564915],
								[107.392344, 31.565941],
								[107.388959, 31.56395],
								[107.381474, 31.554817],
								[107.387718, 31.544859],
								[107.379894, 31.536348],
								[107.38202, 31.525483],
								[107.384013, 31.522263],
								[107.390652, 31.523511],
								[107.396312, 31.529829],
								[107.404098, 31.526328],
								[107.402387, 31.520633],
								[107.409101, 31.518198],
								[107.416266, 31.523168],
								[107.421306, 31.522927],
								[107.430916, 31.525201],
								[107.430051, 31.529447],
								[107.43259, 31.533652],
								[107.444701, 31.526187],
								[107.454255, 31.526187],
								[107.466516, 31.529225],
								[107.474415, 31.520492],
								[107.482784, 31.519103],
								[107.496851, 31.506243],
								[107.506348, 31.506786],
								[107.52066, 31.516407],
								[107.526715, 31.515501],
								[107.531379, 31.505539],
								[107.529781, 31.500547],
								[107.5238, 31.494065],
								[107.522653, 31.490341],
								[107.534313, 31.485691],
								[107.534557, 31.483859],
								[107.525831, 31.472242],
								[107.529574, 31.467571],
								[107.529555, 31.461591],
								[107.523161, 31.461369],
								[107.516221, 31.456174],
								[107.515732, 31.444493],
								[107.508041, 31.440183],
								[107.499785, 31.433637],
								[107.497848, 31.426586],
								[107.503922, 31.417038],
								[107.512817, 31.409966],
								[107.518497, 31.407407],
								[107.528408, 31.406964],
								[107.532545, 31.404002],
								[107.537247, 31.40505],
								[107.541083, 31.410047],
								[107.54571, 31.40912],
								[107.549245, 31.414076],
								[107.555978, 31.416332],
								[107.560755, 31.415023],
								[107.560266, 31.411034],
								[107.553947, 31.412766],
								[107.55579, 31.407246],
								[107.570534, 31.404304],
								[107.571437, 31.402189],
								[107.564516, 31.400939],
								[107.564516, 31.395781],
								[107.569443, 31.39699],
								[107.572132, 31.389856],
								[107.566716, 31.386854],
								[107.563444, 31.380667],
								[107.563218, 31.375588],
								[107.555752, 31.372947],
								[107.554304, 31.366296],
								[107.557313, 31.362526],
								[107.552085, 31.354281],
								[107.556674, 31.34664],
								[107.552931, 31.333978],
								[107.556222, 31.332204],
								[107.563952, 31.3342],
								[107.574972, 31.333635],
								[107.578602, 31.336741],
								[107.585146, 31.335087],
								[107.588193, 31.329885],
								[107.58336, 31.323936],
								[107.581611, 31.31474],
								[107.583473, 31.306027],
								[107.595283, 31.304635],
								[107.598142, 31.29812],
								[107.60211, 31.293661],
								[107.592989, 31.285653],
								[107.595828, 31.278793],
								[107.60211, 31.274617],
								[107.604498, 31.268886],
								[107.608635, 31.267131],
								[107.620032, 31.267716],
								[107.622759, 31.269048],
								[107.631071, 31.263317],
								[107.631504, 31.258897],
								[107.627254, 31.247878],
								[107.626765, 31.24148],
								[107.634682, 31.235849],
								[107.63677, 31.223273],
								[107.633084, 31.218488],
								[107.634551, 31.207727],
								[107.630902, 31.205243],
								[107.633215, 31.196783],
								[107.632256, 31.194259],
								[107.623229, 31.187675],
								[107.621142, 31.181414],
								[107.613525, 31.185393],
								[107.608673, 31.18307],
								[107.603727, 31.177718],
								[107.604291, 31.174507],
								[107.60572, 31.171639],
								[107.600944, 31.167861],
								[107.603407, 31.165639],
								[107.610554, 31.164609],
								[107.613243, 31.154347],
								[107.617474, 31.152609],
								[107.626671, 31.144811],
								[107.63393, 31.137153],
								[107.638838, 31.128383],
								[107.643389, 31.127939],
								[107.649802, 31.124382],
								[107.653789, 31.128767],
								[107.656855, 31.127171],
								[107.661218, 31.119228],
								[107.671806, 31.113569],
								[107.680626, 31.11072],
								[107.686174, 31.107405],
								[107.689559, 31.112114],
								[107.695652, 31.126019],
								[107.701727, 31.132708],
								[107.71081, 31.142608],
								[107.718464, 31.15566],
								[107.722169, 31.158933],
								[107.723674, 31.163922],
								[107.729109, 31.168427],
								[107.739546, 31.181657],
								[107.747388, 31.193471],
								[107.755212, 31.198842],
								[107.758917, 31.205789],
								[107.771742, 31.217398],
								[107.78139, 31.231004],
								[107.787747, 31.232902],
								[107.789326, 31.235425],
								[107.789251, 31.247495],
								[107.792016, 31.251572],
								[107.798184, 31.255305],
								[107.803713, 31.254861],
								[107.816727, 31.245154],
								[107.819097, 31.238089],
								[107.825773, 31.231731],
								[107.845031, 31.219861],
								[107.851932, 31.220689],
								[107.858853, 31.214107],
								[107.869309, 31.211927],
								[107.887119, 31.204375],
								[107.890203, 31.215904],
								[107.893269, 31.219276],
								[107.90286, 31.223131],
								[107.916927, 31.218549],
								[107.924205, 31.219154],
								[107.927365, 31.225574],
								[107.931558, 31.227007],
								[107.936335, 31.223091],
								[107.937501, 31.217963],
								[107.935019, 31.20171],
								[107.943858, 31.204577],
								[107.954841, 31.212896],
								[107.967572, 31.216954],
								[107.980962, 31.225453],
								[108.001386, 31.23048],
								[108.003455, 31.233649],
								[108.015773, 31.238715],
								[108.019929, 31.24255]
							]
						]
					]
				}
			}, {
				"type": "Feature",
				"properties": {
					"adcode": 511723,
					"name": "开江县",
					"center": [107.864135, 31.085537],
					"centroid": [107.884059, 31.045626],
					"childrenNum": 0,
					"level": "district",
					"parent": {
						"adcode": 511700
					},
					"subFeatureIndex": 3,
					"acroutes": [100000, 510000, 511700]
				},
				"geometry": {
					"type": "MultiPolygon",
					"coordinates": [
						[
							[
								[108.019929, 31.24255],
								[108.015773, 31.238715],
								[108.003455, 31.233649],
								[108.001386, 31.23048],
								[107.980962, 31.225453],
								[107.967572, 31.216954],
								[107.954841, 31.212896],
								[107.943858, 31.204577],
								[107.935019, 31.20171],
								[107.937501, 31.217963],
								[107.936335, 31.223091],
								[107.931558, 31.227007],
								[107.927365, 31.225574],
								[107.924205, 31.219154],
								[107.916927, 31.218549],
								[107.90286, 31.223131],
								[107.893269, 31.219276],
								[107.890203, 31.215904],
								[107.887119, 31.204375],
								[107.869309, 31.211927],
								[107.858853, 31.214107],
								[107.851932, 31.220689],
								[107.845031, 31.219861],
								[107.825773, 31.231731],
								[107.819097, 31.238089],
								[107.816727, 31.245154],
								[107.803713, 31.254861],
								[107.798184, 31.255305],
								[107.792016, 31.251572],
								[107.789251, 31.247495],
								[107.789326, 31.235425],
								[107.787747, 31.232902],
								[107.78139, 31.231004],
								[107.771742, 31.217398],
								[107.758917, 31.205789],
								[107.755212, 31.198842],
								[107.747388, 31.193471],
								[107.739546, 31.181657],
								[107.729109, 31.168427],
								[107.723674, 31.163922],
								[107.722169, 31.158933],
								[107.718464, 31.15566],
								[107.71081, 31.142608],
								[107.701727, 31.132708],
								[107.716997, 31.124402],
								[107.721849, 31.115409],
								[107.725197, 31.112397],
								[107.728507, 31.1146],
								[107.732776, 31.125311],
								[107.742235, 31.12422],
								[107.761643, 31.125453],
								[107.764784, 31.122886],
								[107.762979, 31.112458],
								[107.76958, 31.105727],
								[107.779509, 31.103625],
								[107.783384, 31.100169],
								[107.780638, 31.093397],
								[107.782744, 31.082924],
								[107.774657, 31.070308],
								[107.777591, 31.055708],
								[107.789477, 31.054656],
								[107.795326, 31.041813],
								[107.798316, 31.039851],
								[107.813455, 31.03797],
								[107.818927, 31.0335],
								[107.830963, 31.026864],
								[107.835345, 31.021018],
								[107.834386, 31.017983],
								[107.820281, 31.012986],
								[107.815618, 31.009304],
								[107.803826, 31.004528],
								[107.802829, 30.997729],
								[107.799707, 30.99006],
								[107.790662, 30.986801],
								[107.797601, 30.984393],
								[107.801757, 30.980669],
								[107.797469, 30.977411],
								[107.802378, 30.974415],
								[107.801061, 30.970933],
								[107.79604, 30.968302],
								[107.785904, 30.966521],
								[107.7721, 30.959132],
								[107.768376, 30.951905],
								[107.759556, 30.944697],
								[107.754723, 30.937995],
								[107.743514, 30.927951],
								[107.729917, 30.909359],
								[107.724595, 30.904134],
								[107.715512, 30.887502],
								[107.717844, 30.888313],
								[107.739659, 30.88418],
								[107.742781, 30.877028],
								[107.74957, 30.873482],
								[107.750059, 30.864809],
								[107.75335, 30.86637],
								[107.760647, 30.862864],
								[107.757111, 30.859277],
								[107.757675, 30.855852],
								[107.752804, 30.850745],
								[107.752504, 30.845273],
								[107.757431, 30.840246],
								[107.754648, 30.8383],
								[107.758296, 30.831753],
								[107.755475, 30.829706],
								[107.76328, 30.822773],
								[107.763825, 30.817137],
								[107.775391, 30.814846],
								[107.783703, 30.819306],
								[107.790003, 30.818738],
								[107.7992, 30.814947],
								[107.807343, 30.808642],
								[107.818871, 30.797449],
								[107.831377, 30.798402],
								[107.842304, 30.79392],
								[107.849845, 30.792602],
								[107.860621, 30.801869],
								[107.88505, 30.820279],
								[107.894247, 30.833213],
								[107.913485, 30.846327],
								[107.92949, 30.859338],
								[107.942109, 30.865073],
								[107.955292, 30.873279],
								[107.95595, 30.882964],
								[107.963078, 30.887968],
								[107.97833, 30.896173],
								[107.98698, 30.901926],
								[107.992246, 30.902614],
								[107.994785, 30.908529],
								[107.982448, 30.913248],
								[107.96872, 30.912114],
								[107.958658, 30.919567],
								[107.948277, 30.919041],
								[107.944441, 30.924347],
								[107.942616, 30.93518],
								[107.937106, 30.935666],
								[107.938761, 30.94004],
								[107.936316, 30.952755],
								[107.937388, 30.968687],
								[107.941187, 30.976135],
								[107.941996, 30.987813],
								[107.943519, 30.989432],
								[107.951907, 30.986518],
								[107.964827, 30.985446],
								[107.971541, 30.98243],
								[107.981132, 30.983361],
								[107.987432, 30.989635],
								[107.990328, 30.998094],
								[107.995895, 31.004306],
								[107.997794, 31.013006],
								[108.001687, 31.018833],
								[108.003812, 31.025408],
								[108.011053, 31.023668],
								[108.022506, 31.029919],
								[108.02322, 31.032347],
								[108.03362, 31.036129],
								[108.043343, 31.035421],
								[108.053273, 31.040761],
								[108.059422, 31.049458],
								[108.058858, 31.053908],
								[108.050245, 31.059954],
								[108.044941, 31.061997],
								[108.028956, 31.061734],
								[108.024066, 31.066223],
								[108.027376, 31.069317],
								[108.024725, 31.089252],
								[108.013855, 31.098713],
								[108.01267, 31.104514],
								[108.009416, 31.108294],
								[108.014776, 31.115287],
								[108.017842, 31.113044],
								[108.025721, 31.116338],
								[108.027132, 31.122462],
								[108.032022, 31.128484],
								[108.035331, 31.128989],
								[108.033846, 31.135597],
								[108.036291, 31.139941],
								[108.045637, 31.145902],
								[108.052408, 31.144972],
								[108.058219, 31.147942],
								[108.054646, 31.154892],
								[108.061284, 31.162549],
								[108.073, 31.171315],
								[108.068073, 31.173073],
								[108.070274, 31.177779],
								[108.07868, 31.186685],
								[108.083776, 31.185716],
								[108.089155, 31.198903],
								[108.089757, 31.204557],
								[108.080711, 31.218953],
								[108.07112, 31.218347],
								[108.06907, 31.222667],
								[108.076235, 31.225009],
								[108.07853, 31.229894],
								[108.076273, 31.231832],
								[108.040071, 31.218629],
								[108.031589, 31.217075],
								[108.027903, 31.222001],
								[108.031645, 31.236838],
								[108.026981, 31.241864],
								[108.019929, 31.24255]
							]
						]
					]
				}
			}, {
				"type": "Feature",
				"properties": {
					"adcode": 511724,
					"name": "大竹县",
					"center": [107.20742, 30.736289],
					"centroid": [107.272827, 30.685153],
					"childrenNum": 0,
					"level": "district",
					"parent": {
						"adcode": 511700
					},
					"subFeatureIndex": 4,
					"acroutes": [100000, 510000, 511700]
				},
				"geometry": {
					"type": "MultiPolygon",
					"coordinates": [
						[
							[
								[107.287725, 30.363049],
								[107.295266, 30.361847],
								[107.304745, 30.35488],
								[107.318755, 30.364291],
								[107.327406, 30.372561],
								[107.339028, 30.387082],
								[107.339781, 30.39425],
								[107.342808, 30.401682],
								[107.341379, 30.412147],
								[107.345347, 30.417155],
								[107.345535, 30.425501],
								[107.350801, 30.433094],
								[107.352042, 30.440197],
								[107.360035, 30.456255],
								[107.367783, 30.467345],
								[107.374177, 30.474079],
								[107.381079, 30.485086],
								[107.388846, 30.490884],
								[107.392401, 30.497495],
								[107.403101, 30.511651],
								[107.408743, 30.521576],
								[107.419933, 30.516492],
								[107.43385, 30.523569],
								[107.441278, 30.531194],
								[107.442839, 30.535302],
								[107.437686, 30.537701],
								[107.42755, 30.547623],
								[107.433135, 30.550286],
								[107.439905, 30.5573],
								[107.450268, 30.565634],
								[107.453032, 30.569781],
								[107.460254, 30.571346],
								[107.461119, 30.577098],
								[107.466817, 30.580594],
								[107.467419, 30.586304],
								[107.478383, 30.592767],
								[107.485341, 30.598335],
								[107.493729, 30.618978],
								[107.508003, 30.635351],
								[107.516673, 30.644227],
								[107.515732, 30.649385],
								[107.510373, 30.650746],
								[107.499164, 30.660149],
								[107.489065, 30.663479],
								[107.477443, 30.664779],
								[107.472459, 30.670505],
								[107.472233, 30.675155],
								[107.467005, 30.67887],
								[107.461382, 30.679154],
								[107.456587, 30.682707],
								[107.46095, 30.687499],
								[107.458336, 30.693771],
								[107.458636, 30.704794],
								[107.452788, 30.706641],
								[107.437047, 30.721051],
								[107.431443, 30.728884],
								[107.430295, 30.734951],
								[107.424353, 30.740673],
								[107.425669, 30.74674],
								[107.43904, 30.747065],
								[107.439755, 30.750493],
								[107.445039, 30.752522],
								[107.440921, 30.755423],
								[107.439586, 30.759785],
								[107.444118, 30.764369],
								[107.446807, 30.77011],
								[107.445566, 30.778182],
								[107.447879, 30.77875],
								[107.453784, 30.771651],
								[107.459859, 30.772463],
								[107.462736, 30.770171],
								[107.466366, 30.774045],
								[107.46567, 30.777371],
								[107.46031, 30.784166],
								[107.473437, 30.785768],
								[107.473888, 30.791345],
								[107.477255, 30.792257],
								[107.477762, 30.799781],
								[107.489479, 30.803836],
								[107.493785, 30.803674],
								[107.490193, 30.81077],
								[107.498957, 30.81077],
								[107.49213, 30.815474],
								[107.494876, 30.821151],
								[107.490024, 30.82111],
								[107.489309, 30.825043],
								[107.492845, 30.829827],
								[107.492112, 30.833152],
								[107.486639, 30.833496],
								[107.480508, 30.837368],
								[107.498543, 30.847948],
								[107.506856, 30.849448],
								[107.513062, 30.8549],
								[107.52664, 30.86564],
								[107.540876, 30.883471],
								[107.544073, 30.892972],
								[107.543735, 30.896537],
								[107.534031, 30.90782],
								[107.521355, 30.916023],
								[107.516579, 30.920944],
								[107.506216, 30.911709],
								[107.494274, 30.915273],
								[107.477612, 30.924691],
								[107.471556, 30.920985],
								[107.464391, 30.909076],
								[107.463413, 30.904478],
								[107.45952, 30.90144],
								[107.453747, 30.902007],
								[107.446431, 30.895362],
								[107.441053, 30.896254],
								[107.435467, 30.901521],
								[107.43558, 30.905369],
								[107.429242, 30.913025],
								[107.420196, 30.914079],
								[107.416492, 30.912175],
								[107.409778, 30.915253],
								[107.402199, 30.915922],
								[107.392946, 30.918575],
								[107.392965, 30.926594],
								[107.390426, 30.932001],
								[107.382283, 30.931961],
								[107.375813, 30.938744],
								[107.369795, 30.937995],
								[107.365771, 30.944616],
								[107.36169, 30.938116],
								[107.354318, 30.934755],
								[107.350105, 30.936881],
								[107.349203, 30.932325],
								[107.346081, 30.936294],
								[107.335831, 30.933317],
								[107.332578, 30.9368],
								[107.326485, 30.93273],
								[107.325619, 30.937266],
								[107.320184, 30.935444],
								[107.316574, 30.941943],
								[107.312267, 30.939271],
								[107.314862, 30.946256],
								[107.30119, 30.952208],
								[107.30324, 30.956986],
								[107.296902, 30.955184],
								[107.291956, 30.957168],
								[107.281049, 30.956318],
								[107.276366, 30.958282],
								[107.269276, 30.953848],
								[107.269407, 30.958201],
								[107.261001, 30.959112],
								[107.260757, 30.965266],
								[107.264123, 30.971419],
								[107.260268, 30.979617],
								[107.267339, 30.984292],
								[107.270592, 30.988299],
								[107.270762, 30.992751],
								[107.265778, 30.993298],
								[107.260794, 30.99095],
								[107.265703, 31.002788],
								[107.261358, 31.005823],
								[107.254757, 31.000947],
								[107.245298, 30.997244],
								[107.224065, 30.9969],
								[107.217107, 30.989432],
								[107.207234, 30.982167],
								[107.19911, 30.974354],
								[107.189368, 30.962169],
								[107.180002, 30.946134],
								[107.180886, 30.940931],
								[107.173063, 30.932021],
								[107.168098, 30.930746],
								[107.162306, 30.917137],
								[107.158544, 30.914139],
								[107.158958, 30.904174],
								[107.155065, 30.899637],
								[107.14649, 30.894795],
								[107.145888, 30.887158],
								[107.141149, 30.883309],
								[107.14192, 30.877007],
								[107.136691, 30.866552],
								[107.141055, 30.852184],
								[107.137745, 30.842942],
								[107.124994, 30.836071],
								[107.122417, 30.833598],
								[107.118205, 30.823138],
								[107.109592, 30.812656],
								[107.105134, 30.804951],
								[107.100771, 30.802863],
								[107.093738, 30.793657],
								[107.09669, 30.790351],
								[107.095318, 30.78737],
								[107.087588, 30.783983],
								[107.08646, 30.775323],
								[107.081627, 30.771733],
								[107.081683, 30.765485],
								[107.086855, 30.76088],
								[107.095863, 30.760272],
								[107.10064, 30.756579],
								[107.099699, 30.74534],
								[107.092628, 30.728539],
								[107.083244, 30.71547],
								[107.08095, 30.710477],
								[107.07495, 30.705565],
								[107.065039, 30.6998],
								[107.059868, 30.698217],
								[107.056953, 30.683357],
								[107.050559, 30.669144],
								[107.04723, 30.65627],
								[107.043751, 30.650909],
								[107.043243, 30.642785],
								[107.039707, 30.628586],
								[107.036322, 30.625113],
								[107.03463, 30.616946],
								[107.03211, 30.613248],
								[107.032749, 30.604044],
								[107.027671, 30.588987],
								[107.026957, 30.580289],
								[107.021917, 30.57474],
								[107.021691, 30.559129],
								[107.017723, 30.548639],
								[107.008056, 30.544106],
								[106.996039, 30.544106],
								[106.98846, 30.547196],
								[106.984605, 30.546505],
								[106.984812, 30.539145],
								[106.974619, 30.533716],
								[106.97665, 30.523345],
								[106.975954, 30.514804],
								[106.982912, 30.50677],
								[106.983514, 30.499468],
								[106.989419, 30.502621],
								[106.991225, 30.505814],
								[106.997337, 30.50439],
								[107.002396, 30.506404],
								[107.004803, 30.513339],
								[107.010934, 30.520661],
								[107.02596, 30.52367],
								[107.03431, 30.519522],
								[107.04187, 30.513177],
								[107.047531, 30.512241],
								[107.05936, 30.513258],
								[107.066017, 30.516085],
								[107.076474, 30.514072],
								[107.080555, 30.507563],
								[107.077245, 30.502438],
								[107.081664, 30.494403],
								[107.088134, 30.493894],
								[107.081721, 30.48767],
								[107.082341, 30.486185],
								[107.098628, 30.486266],
								[107.105623, 30.489724],
								[107.108426, 30.488585],
								[107.108181, 30.483418],
								[107.113804, 30.482909],
								[107.118167, 30.485656],
								[107.120443, 30.482584],
								[107.119202, 30.477762],
								[107.122812, 30.471699],
								[107.127777, 30.469522],
								[107.142559, 30.47003],
								[107.137839, 30.479451],
								[107.131332, 30.486347],
								[107.123903, 30.489582],
								[107.115365, 30.497759],
								[107.104063, 30.500424],
								[107.095299, 30.506282],
								[107.09434, 30.509556],
								[107.100301, 30.515434],
								[107.100226, 30.520925],
								[107.1123, 30.524931],
								[107.114632, 30.517468],
								[107.118318, 30.514783],
								[107.126028, 30.515373],
								[107.125803, 30.519685],
								[107.121364, 30.52367],
								[107.142051, 30.520681],
								[107.153523, 30.510634],
								[107.158488, 30.508214],
								[107.166086, 30.507238],
								[107.174379, 30.502885],
								[107.179024, 30.506262],
								[107.181845, 30.512648],
								[107.1876, 30.514946],
								[107.188766, 30.526863],
								[107.183576, 30.534712],
								[107.192189, 30.53457],
								[107.196552, 30.529039],
								[107.204262, 30.524321],
								[107.206914, 30.516166],
								[107.204751, 30.511712],
								[107.211766, 30.50858],
								[107.213929, 30.501177],
								[107.206877, 30.494708],
								[107.195668, 30.492064],
								[107.185061, 30.477965],
								[107.175809, 30.46881],
								[107.170204, 30.465473],
								[107.163528, 30.464537],
								[107.162324, 30.461037],
								[107.170392, 30.45422],
								[107.178611, 30.450984],
								[107.186171, 30.450455],
								[107.184553, 30.439831],
								[107.192903, 30.431221],
								[107.204225, 30.43116],
								[107.212819, 30.436106],
								[107.219665, 30.436961],
								[107.227263, 30.432544],
								[107.225344, 30.424158],
								[107.227338, 30.420534],
								[107.230196, 30.42084],
								[107.23172, 30.414692],
								[107.227714, 30.414183],
								[107.21754, 30.406466],
								[107.217446, 30.396673],
								[107.211879, 30.396164],
								[107.208814, 30.391114],
								[107.214249, 30.388385],
								[107.217126, 30.38252],
								[107.22352, 30.382479],
								[107.229576, 30.377978],
								[107.229388, 30.382744],
								[107.243869, 30.386572],
								[107.236515, 30.393455],
								[107.244978, 30.395044],
								[107.245655, 30.391378],
								[107.252444, 30.38755],
								[107.252331, 30.383294],
								[107.248326, 30.383396],
								[107.247423, 30.378976],
								[107.242909, 30.378304],
								[107.24195, 30.383049],
								[107.238227, 30.383416],
								[107.236685, 30.377897],
								[107.23187, 30.377653],
								[107.233393, 30.371318],
								[107.229388, 30.370259],
								[107.227507, 30.359056],
								[107.232321, 30.357997],
								[107.230046, 30.352925],
								[107.226078, 30.353455],
								[107.221282, 30.346121],
								[107.222354, 30.343615],
								[107.230328, 30.341313],
								[107.238772, 30.34665],
								[107.238622, 30.349421],
								[107.240408, 30.350501],
								[107.241668, 30.347873],
								[107.249698, 30.347037],
								[107.257578, 30.336545],
								[107.261133, 30.326887],
								[107.263916, 30.323545],
								[107.272266, 30.322139],
								[107.281763, 30.331716],
								[107.283174, 30.336504],
								[107.278754, 30.346161],
								[107.271439, 30.345061],
								[107.267245, 30.343554],
								[107.262336, 30.347384],
								[107.265609, 30.349339],
								[107.27661, 30.352395],
								[107.276291, 30.357223],
								[107.274335, 30.37085],
								[107.266361, 30.373151],
								[107.26307, 30.37694],
								[107.265195, 30.379017],
								[107.261415, 30.383029],
								[107.257033, 30.379832],
								[107.255735, 30.382561],
								[107.26038, 30.386287],
								[107.268279, 30.383742],
								[107.269144, 30.379465],
								[107.277494, 30.371563],
								[107.287725, 30.363049]
							]
						],
						[
							[
								[107.21754, 30.406466],
								[107.227714, 30.414183],
								[107.222787, 30.421186],
								[107.227338, 30.420534],
								[107.225344, 30.424158],
								[107.215941, 30.421125],
								[107.215452, 30.415201],
								[107.206801, 30.41461],
								[107.206858, 30.41005],
								[107.21754, 30.406466]
							]
						],
						[
							[
								[107.25282, 30.32487],
								[107.247818, 30.324116],
								[107.254588, 30.322322],
								[107.25282, 30.32487]
							]
						],
						[
							[
								[107.277494, 30.371563],
								[107.274335, 30.37085],
								[107.276291, 30.357223],
								[107.287725, 30.363049],
								[107.277494, 30.371563]
							]
						],
						[
							[
								[107.27661, 30.352395],
								[107.265609, 30.349339],
								[107.271439, 30.345061],
								[107.278754, 30.346161],
								[107.27661, 30.352395]
							]
						],
						[
							[
								[107.240408, 30.350501],
								[107.238622, 30.349421],
								[107.238772, 30.34665],
								[107.241668, 30.347873],
								[107.240408, 30.350501]
							]
						]
					]
				}
			}, {
				"type": "Feature",
				"properties": {
					"adcode": 511725,
					"name": "渠县",
					"center": [106.970746, 30.836348],
					"centroid": [106.9815, 30.942875],
					"childrenNum": 0,
					"level": "district",
					"parent": {
						"adcode": 511700
					},
					"subFeatureIndex": 5,
					"acroutes": [100000, 510000, 511700]
				},
				"geometry": {
					"type": "MultiPolygon",
					"coordinates": [
						[
							[
								[106.994798, 31.26142],
								[106.99083, 31.263357],
								[106.987595, 31.260714],
								[106.983251, 31.248746],
								[106.97885, 31.248222],
								[106.975916, 31.252056],
								[106.971064, 31.252783],
								[106.96499, 31.251027],
								[106.961342, 31.246223],
								[106.963034, 31.238816],
								[106.958276, 31.228299],
								[106.958558, 31.216308],
								[106.954853, 31.217055],
								[106.951938, 31.221173],
								[106.947049, 31.210513],
								[106.939865, 31.207121],
								[106.939451, 31.199206],
								[106.936705, 31.195955],
								[106.938849, 31.189695],
								[106.937138, 31.179637],
								[106.938323, 31.176102],
								[106.949418, 31.165983],
								[106.95096, 31.161236],
								[106.944999, 31.152973],
								[106.945826, 31.146508],
								[106.937476, 31.140204],
								[106.939188, 31.133496],
								[106.931327, 31.133071],
								[106.930857, 31.125999],
								[106.935257, 31.119006],
								[106.931853, 31.111811],
								[106.92608, 31.111771],
								[106.909004, 31.107647],
								[106.903362, 31.101907],
								[106.900654, 31.091921],
								[106.894786, 31.087554],
								[106.890404, 31.087514],
								[106.881998, 31.083875],
								[106.871899, 31.083309],
								[106.859675, 31.071683],
								[106.843482, 31.065677],
								[106.837107, 31.05684],
								[106.830563, 31.052856],
								[106.833101, 31.043714],
								[106.829792, 31.041793],
								[106.827008, 31.035522],
								[106.823397, 31.032387],
								[106.818903, 31.020715],
								[106.819749, 31.01163],
								[106.813712, 31.008312],
								[106.81202, 31.001311],
								[106.802955, 30.996616],
								[106.803256, 30.993642],
								[106.79673, 30.993682],
								[106.787421, 30.995868],
								[106.784788, 30.988582],
								[106.788267, 30.986194],
								[106.783227, 30.983037],
								[106.786067, 30.977714],
								[106.784769, 30.97231],
								[106.781422, 30.9698],
								[106.77153, 30.968666],
								[106.762333, 30.973949],
								[106.746837, 30.976014],
								[106.731604, 30.973666],
								[106.742587, 30.959699],
								[106.743076, 30.956318],
								[106.739916, 30.950568],
								[106.733296, 30.947309],
								[106.723179, 30.946661],
								[106.717424, 30.934006],
								[106.717198, 30.928721],
								[106.712704, 30.925886],
								[106.70725, 30.915922],
								[106.699596, 30.915051],
								[106.695308, 30.919041],
								[106.677348, 30.923071],
								[106.673624, 30.922058],
								[106.666496, 30.913977],
								[106.666459, 30.909967],
								[106.681015, 30.898442],
								[106.687146, 30.890987],
								[106.689214, 30.883612],
								[106.68613, 30.876339],
								[106.678927, 30.873117],
								[106.675486, 30.867099],
								[106.67904, 30.85946],
								[106.678363, 30.851069],
								[106.673248, 30.847381],
								[106.675166, 30.838868],
								[106.67825, 30.838098],
								[106.68188, 30.832321],
								[106.686412, 30.829158],
								[106.691076, 30.831429],
								[106.700348, 30.830942],
								[106.710466, 30.835463],
								[106.725793, 30.832382],
								[106.730419, 30.832584],
								[106.732864, 30.82778],
								[106.73072, 30.822408],
								[106.739108, 30.807891],
								[106.747119, 30.810811],
								[106.756353, 30.809007],
								[106.757519, 30.804992],
								[106.752987, 30.798402],
								[106.755808, 30.792298],
								[106.763067, 30.788911],
								[106.769724, 30.789986],
								[106.778319, 30.784429],
								[106.78255, 30.783314],
								[106.785916, 30.778507],
								[106.795395, 30.779136],
								[106.800961, 30.777412],
								[106.806171, 30.770536],
								[106.803782, 30.761124],
								[106.809932, 30.763172],
								[106.821874, 30.758547],
								[106.831296, 30.756965],
								[106.827666, 30.7524],
								[106.831954, 30.745401],
								[106.840417, 30.73836],
								[106.8473, 30.736473],
								[106.850761, 30.738036],
								[106.859487, 30.731847],
								[106.864696, 30.735763],
								[106.880474, 30.733957],
								[106.88369, 30.731015],
								[106.880211, 30.72653],
								[106.883371, 30.723953],
								[106.880587, 30.721173],
								[106.883634, 30.715632],
								[106.888486, 30.717459],
								[106.890517, 30.713887],
								[106.896497, 30.718595],
								[106.903381, 30.71066],
								[106.919272, 30.706763],
								[106.920682, 30.704083],
								[106.930725, 30.709746],
								[106.930556, 30.707128],
								[106.939827, 30.706986],
								[106.943325, 30.709178],
								[106.949249, 30.707777],
								[106.942573, 30.695659],
								[106.93443, 30.688554],
								[106.926512, 30.684798],
								[106.922262, 30.68559],
								[106.914307, 30.69095],
								[106.905224, 30.701891],
								[106.897626, 30.702358],
								[106.890348, 30.697628],
								[106.892999, 30.687093],
								[106.900635, 30.679134],
								[106.904264, 30.66419],
								[106.909944, 30.657509],
								[106.923202, 30.654057],
								[106.928224, 30.647598],
								[106.936743, 30.649568],
								[106.943513, 30.647598],
								[106.943833, 30.643374],
								[106.950171, 30.640185],
								[106.949306, 30.635696],
								[106.954966, 30.635432],
								[106.959799, 30.637565],
								[106.961229, 30.642054],
								[106.968582, 30.645527],
								[106.974431, 30.640754],
								[106.979508, 30.641871],
								[106.983834, 30.636224],
								[106.988855, 30.637504],
								[107.000026, 30.635737],
								[107.003637, 30.638865],
								[107.013397, 30.639921],
								[107.020619, 30.643942],
								[107.027502, 30.644816],
								[107.029928, 30.642683],
								[107.037902, 30.641404],
								[107.043243, 30.642785],
								[107.043751, 30.650909],
								[107.04723, 30.65627],
								[107.050559, 30.669144],
								[107.056953, 30.683357],
								[107.059868, 30.698217],
								[107.065039, 30.6998],
								[107.07495, 30.705565],
								[107.08095, 30.710477],
								[107.083244, 30.71547],
								[107.092628, 30.728539],
								[107.099699, 30.74534],
								[107.10064, 30.756579],
								[107.095863, 30.760272],
								[107.086855, 30.76088],
								[107.081683, 30.765485],
								[107.081627, 30.771733],
								[107.08646, 30.775323],
								[107.087588, 30.783983],
								[107.095318, 30.78737],
								[107.09669, 30.790351],
								[107.093738, 30.793657],
								[107.100771, 30.802863],
								[107.105134, 30.804951],
								[107.109592, 30.812656],
								[107.118205, 30.823138],
								[107.122417, 30.833598],
								[107.124994, 30.836071],
								[107.137745, 30.842942],
								[107.141055, 30.852184],
								[107.136691, 30.866552],
								[107.14192, 30.877007],
								[107.141149, 30.883309],
								[107.145888, 30.887158],
								[107.14649, 30.894795],
								[107.155065, 30.899637],
								[107.158958, 30.904174],
								[107.158544, 30.914139],
								[107.162306, 30.917137],
								[107.168098, 30.930746],
								[107.173063, 30.932021],
								[107.180886, 30.940931],
								[107.180002, 30.946134],
								[107.189368, 30.962169],
								[107.19911, 30.974354],
								[107.207234, 30.982167],
								[107.217107, 30.989432],
								[107.224065, 30.9969],
								[107.245298, 30.997244],
								[107.254757, 31.000947],
								[107.261358, 31.005823],
								[107.267113, 31.01509],
								[107.266286, 31.017478],
								[107.259403, 31.019764],
								[107.253836, 31.024073],
								[107.248627, 31.02559],
								[107.242251, 31.031376],
								[107.235424, 31.035219],
								[107.234221, 31.041287],
								[107.230196, 31.044746],
								[107.229989, 31.051056],
								[107.235293, 31.058761],
								[107.233205, 31.066425],
								[107.226567, 31.071299],
								[107.226191, 31.081206],
								[107.216298, 31.088707],
								[107.197267, 31.084077],
								[107.187468, 31.084319],
								[107.184948, 31.087756],
								[107.193392, 31.103484],
								[107.196458, 31.110821],
								[107.195894, 31.123796],
								[107.183726, 31.126281],
								[107.186528, 31.130707],
								[107.184121, 31.13879],
								[107.190496, 31.143215],
								[107.194145, 31.149438],
								[107.198244, 31.151296],
								[107.200915, 31.162205],
								[107.201291, 31.17794],
								[107.194991, 31.181818],
								[107.19123, 31.181192],
								[107.189537, 31.185393],
								[107.195029, 31.185211],
								[107.209265, 31.187917],
								[107.215339, 31.187433],
								[107.222298, 31.194501],
								[107.227808, 31.197247],
								[107.226435, 31.206616],
								[107.211898, 31.207343],
								[107.205692, 31.201528],
								[107.201347, 31.205728],
								[107.197417, 31.200983],
								[107.190383, 31.202255],
								[107.18854, 31.205001],
								[107.184008, 31.204113],
								[107.181526, 31.207],
								[107.177106, 31.206899],
								[107.173552, 31.210008],
								[107.16082, 31.203628],
								[107.160933, 31.213037],
								[107.158413, 31.216772],
								[107.143687, 31.215197],
								[107.139757, 31.206091],
								[107.134341, 31.202497],
								[107.128868, 31.195329],
								[107.12443, 31.195531],
								[107.122812, 31.199347],
								[107.118731, 31.199004],
								[107.115685, 31.194985],
								[107.114632, 31.184908],
								[107.09355, 31.179071],
								[107.086761, 31.181818],
								[107.075947, 31.194137],
								[107.065209, 31.203184],
								[107.062294, 31.203608],
								[107.056407, 31.19753],
								[107.043281, 31.197712],
								[107.04014, 31.200377],
								[107.029383, 31.204375],
								[107.024644, 31.213098],
								[107.02233, 31.229369],
								[107.013585, 31.245013],
								[107.016783, 31.248787],
								[107.011874, 31.255346],
								[107.004765, 31.255568],
								[106.994798, 31.26142]
							]
						]
					]
				}
			}, {
				"type": "Feature",
				"properties": {
					"adcode": 511781,
					"name": "万源市",
					"center": [108.037548, 32.06777],
					"centroid": [107.988304, 31.980633],
					"childrenNum": 0,
					"level": "district",
					"parent": {
						"adcode": 511700
					},
					"subFeatureIndex": 6,
					"acroutes": [100000, 510000, 511700]
				},
				"geometry": {
					"type": "MultiPolygon",
					"coordinates": [
						[
							[
								[108.45537, 31.78559],
								[108.454411, 31.791088],
								[108.464059, 31.803689],
								[108.465131, 31.808363],
								[108.462498, 31.812817],
								[108.457063, 31.814141],
								[108.443917, 31.81063],
								[108.441453, 31.807621],
								[108.429192, 31.809446],
								[108.422591, 31.814161],
								[108.401283, 31.823368],
								[108.391278, 31.829666],
								[108.389661, 31.840296],
								[108.384113, 31.848598],
								[108.386182, 31.853912],
								[108.379054, 31.855657],
								[108.372716, 31.85299],
								[108.353741, 31.856418],
								[108.3465, 31.859206],
								[108.34099, 31.863536],
								[108.335875, 31.870854],
								[108.335217, 31.876468],
								[108.326246, 31.88162],
								[108.318912, 31.893245],
								[108.308436, 31.905972],
								[108.299466, 31.911283],
								[108.289536, 31.910942],
								[108.282672, 31.917755],
								[108.286414, 31.935086],
								[108.286038, 31.938853],
								[108.280622, 31.949169],
								[108.280152, 31.953055],
								[108.275826, 31.957622],
								[108.271802, 31.958463],
								[108.264693, 31.964672],
								[108.259352, 31.966875],
								[108.265088, 31.969799],
								[108.267044, 31.974265],
								[108.265859, 31.983736],
								[108.269902, 31.985098],
								[108.280133, 31.979051],
								[108.284383, 31.981193],
								[108.283913, 31.986059],
								[108.290364, 31.990084],
								[108.297153, 31.988522],
								[108.307101, 31.997251],
								[108.313721, 31.994629],
								[108.325889, 31.984958],
								[108.338978, 31.980733],
								[108.350487, 31.971962],
								[108.358198, 31.979211],
								[108.367169, 31.984537],
								[108.369501, 31.989863],
								[108.361395, 31.998092],
								[108.350807, 32.002437],
								[108.338451, 32.009523],
								[108.333637, 32.01729],
								[108.329217, 32.019772],
								[108.340106, 32.023114],
								[108.345842, 32.028298],
								[108.356317, 32.03124],
								[108.362862, 32.036784],
								[108.364216, 32.047769],
								[108.359778, 32.053752],
								[108.349735, 32.057374],
								[108.344789, 32.060515],
								[108.34462, 32.068237],
								[108.35045, 32.070658],
								[108.355603, 32.068717],
								[108.365213, 32.071398],
								[108.37377, 32.077159],
								[108.385505, 32.072978],
								[108.39047, 32.067497],
								[108.395171, 32.066316],
								[108.412887, 32.070077],
								[108.41977, 32.064176],
								[108.43, 32.061355],
								[108.449427, 32.072978],
								[108.452417, 32.080899],
								[108.452098, 32.09104],
								[108.439253, 32.09474],
								[108.431637, 32.101699],
								[108.426446, 32.110558],
								[108.422854, 32.112977],
								[108.423964, 32.115797],
								[108.41488, 32.126353],
								[108.412529, 32.13395],
								[108.406643, 32.141346],
								[108.396977, 32.147843],
								[108.391053, 32.147883],
								[108.390846, 32.1513],
								[108.383192, 32.15172],
								[108.388213, 32.157536],
								[108.369425, 32.173323],
								[108.371833, 32.17668],
								[108.379205, 32.177519],
								[108.388627, 32.18671],
								[108.40275, 32.1958],
								[108.406398, 32.1962],
								[108.425261, 32.18743],
								[108.430019, 32.191665],
								[108.434006, 32.192085],
								[108.442901, 32.189228],
								[108.457288, 32.181496],
								[108.468328, 32.182555],
								[108.479329, 32.182055],
								[108.483937, 32.185312],
								[108.488732, 32.191945],
								[108.492945, 32.194921],
								[108.501408, 32.1963],
								[108.50972, 32.201194],
								[108.506072, 32.207207],
								[108.507764, 32.21212],
								[108.514459, 32.21969],
								[108.512485, 32.222746],
								[108.512598, 32.230294],
								[108.507125, 32.24565],
								[108.500975, 32.247806],
								[108.494299, 32.245829],
								[108.483636, 32.25142],
								[108.483692, 32.260284],
								[108.478878, 32.266133],
								[108.47175, 32.270624],
								[108.466372, 32.264995],
								[108.450763, 32.259286],
								[108.437391, 32.260943],
								[108.433724, 32.262879],
								[108.421838, 32.260663],
								[108.414993, 32.252478],
								[108.401039, 32.261382],
								[108.394927, 32.263438],
								[108.38763, 32.26226],
								[108.382364, 32.256571],
								[108.373976, 32.255732],
								[108.370723, 32.253656],
								[108.35643, 32.254634],
								[108.347967, 32.253955],
								[108.34398, 32.248086],
								[108.337906, 32.242755],
								[108.33185, 32.23337],
								[108.325757, 32.23283],
								[108.317614, 32.234488],
								[108.312047, 32.232191],
								[108.302719, 32.236385],
								[108.296194, 32.243354],
								[108.287844, 32.249383],
								[108.281638, 32.249942],
								[108.265163, 32.25699],
								[108.259126, 32.265634],
								[108.251341, 32.272979],
								[108.247184, 32.274796],
								[108.239229, 32.274177],
								[108.230127, 32.266472],
								[108.230334, 32.262879],
								[108.222492, 32.258986],
								[108.216436, 32.259685],
								[108.212186, 32.25699],
								[108.213295, 32.248205],
								[108.208519, 32.242595],
								[108.194038, 32.234728],
								[108.194019, 32.22644],
								[108.1855, 32.221148],
								[108.178861, 32.222526],
								[108.173783, 32.231393],
								[108.165621, 32.233929],
								[108.156651, 32.239001],
								[108.149599, 32.232271],
								[108.145386, 32.221907],
								[108.141662, 32.218891],
								[108.1231, 32.218372],
								[108.117233, 32.223524],
								[108.105347, 32.225102],
								[108.098521, 32.230594],
								[108.089945, 32.233689],
								[108.08041, 32.230374],
								[108.072624, 32.23339],
								[108.066023, 32.229655],
								[108.060758, 32.220469],
								[108.051129, 32.222067],
								[108.049794, 32.218072],
								[108.043287, 32.215256],
								[108.034692, 32.220788],
								[108.031326, 32.220748],
								[108.027094, 32.216235],
								[108.018613, 32.211801],
								[108.018443, 32.209863],
								[108.02527, 32.202912],
								[108.01739, 32.192184],
								[108.020061, 32.18721],
								[108.018594, 32.183374],
								[108.023183, 32.182055],
								[108.023183, 32.175921],
								[108.007818, 32.16501],
								[108.002063, 32.152939],
								[107.995707, 32.147163],
								[107.979383, 32.146044],
								[107.976223, 32.147403],
								[107.968419, 32.156057],
								[107.962984, 32.160174],
								[107.954239, 32.163891],
								[107.952847, 32.168947],
								[107.942635, 32.17696],
								[107.930637, 32.189268],
								[107.924732, 32.196999],
								[107.91358, 32.198497],
								[107.905192, 32.208006],
								[107.900171, 32.207926],
								[107.890523, 32.214397],
								[107.87922, 32.202113],
								[107.867542, 32.202413],
								[107.863931, 32.204091],
								[107.855412, 32.21222],
								[107.844335, 32.216674],
								[107.845614, 32.221647],
								[107.835815, 32.223045],
								[107.830625, 32.227199],
								[107.812289, 32.247986],
								[107.804672, 32.266033],
								[107.795946, 32.274816],
								[107.788706, 32.279865],
								[107.78028, 32.294653],
								[107.776124, 32.30507],
								[107.773172, 32.30926],
								[107.762076, 32.319694],
								[107.7617, 32.324442],
								[107.75666, 32.330526],
								[107.754572, 32.338246],
								[107.736048, 32.333917],
								[107.72766, 32.329748],
								[107.716734, 32.328831],
								[107.710979, 32.322427],
								[107.703588, 32.317998],
								[107.704811, 32.301179],
								[107.704284, 32.293097],
								[107.701821, 32.29162],
								[107.705281, 32.281841],
								[107.698247, 32.276512],
								[107.700899, 32.270145],
								[107.694919, 32.267251],
								[107.689841, 32.260463],
								[107.679046, 32.25142],
								[107.679704, 32.240638],
								[107.684575, 32.235986],
								[107.68813, 32.225462],
								[107.691797, 32.222486],
								[107.689747, 32.218831],
								[107.685064, 32.221927],
								[107.680833, 32.221088],
								[107.672314, 32.215935],
								[107.67647, 32.208884],
								[107.679742, 32.197858],
								[107.671072, 32.199017],
								[107.666897, 32.211541],
								[107.649896, 32.208125],
								[107.637447, 32.201354],
								[107.630996, 32.199696],
								[107.628438, 32.19576],
								[107.630639, 32.189947],
								[107.630112, 32.181316],
								[107.626332, 32.172544],
								[107.62936, 32.171325],
								[107.625824, 32.159814],
								[107.626972, 32.152999],
								[107.639666, 32.146463],
								[107.636638, 32.141626],
								[107.642844, 32.142186],
								[107.648693, 32.140307],
								[107.656892, 32.143125],
								[107.661255, 32.140447],
								[107.668007, 32.139767],
								[107.675078, 32.142286],
								[107.680607, 32.141027],
								[107.689333, 32.13393],
								[107.695351, 32.126093],
								[107.709042, 32.116996],
								[107.715756, 32.119016],
								[107.734581, 32.104459],
								[107.734525, 32.102699],
								[107.742517, 32.102379],
								[107.743495, 32.0959],
								[107.748441, 32.09528],
								[107.750698, 32.09112],
								[107.756848, 32.08582],
								[107.762302, 32.076739],
								[107.763938, 32.069657],
								[107.755343, 32.061455],
								[107.750736, 32.053452],
								[107.745132, 32.050311],
								[107.738756, 32.050391],
								[107.732907, 32.039545],
								[107.722865, 32.037804],
								[107.717148, 32.032881],
								[107.702441, 32.029799],
								[107.69774, 32.022494],
								[107.68497, 32.016709],
								[107.68324, 32.012966],
								[107.67914, 32.012266],
								[107.675229, 32.016849],
								[107.671937, 32.01697],
								[107.672821, 32.011245],
								[107.678463, 32.010624],
								[107.676018, 32.00614],
								[107.66906, 32.009743],
								[107.662177, 32.00652],
								[107.660052, 32.010284],
								[107.655181, 32.012226],
								[107.652793, 32.009423],
								[107.642374, 32.008022],
								[107.622326, 32.014367],
								[107.613187, 32.013046],
								[107.606886, 32.007982],
								[107.600154, 32.007081],
								[107.596712, 32.004519],
								[107.593308, 32.006801],
								[107.584939, 32.000194],
								[107.58808, 31.994248],
								[107.585052, 31.990344],
								[107.575179, 31.989503],
								[107.570271, 31.986299],
								[107.56918, 31.981273],
								[107.581705, 31.972282],
								[107.58415, 31.969198],
								[107.584131, 31.96295],
								[107.579392, 31.959765],
								[107.576213, 31.953997],
								[107.569631, 31.948628],
								[107.561526, 31.94987],
								[107.557614, 31.948228],
								[107.554774, 31.943681],
								[107.54964, 31.944702],
								[107.541177, 31.943861],
								[107.538243, 31.941737],
								[107.537811, 31.935106],
								[107.540876, 31.928535],
								[107.545183, 31.925189],
								[107.53988, 31.924948],
								[107.532338, 31.929777],
								[107.52192, 31.930158],
								[107.515112, 31.926451],
								[107.52049, 31.916954],
								[107.53388, 31.907655],
								[107.53783, 31.902004],
								[107.546988, 31.899619],
								[107.551257, 31.89523],
								[107.549527, 31.891462],
								[107.533354, 31.877049],
								[107.527016, 31.879495],
								[107.526471, 31.88625],
								[107.519983, 31.888295],
								[107.514435, 31.887192],
								[107.511821, 31.883404],
								[107.516635, 31.877209],
								[107.507758, 31.875886],
								[107.50742, 31.865902],
								[107.505596, 31.864739],
								[107.503076, 31.870213],
								[107.497001, 31.868629],
								[107.49655, 31.865461],
								[107.502248, 31.861572],
								[107.507364, 31.860389],
								[107.531473, 31.850764],
								[107.539786, 31.845229],
								[107.549734, 31.844868],
								[107.556561, 31.838371],
								[107.569293, 31.836044],
								[107.575818, 31.821282],
								[107.571681, 31.814823],
								[107.564403, 31.810008],
								[107.564196, 31.802164],
								[107.558216, 31.79175],
								[107.557821, 31.785209],
								[107.554473, 31.768713],
								[107.546894, 31.76504],
								[107.533561, 31.760624],
								[107.533805, 31.753819],
								[107.539052, 31.738401],
								[107.538037, 31.73551],
								[107.52822, 31.731394],
								[107.532225, 31.725893],
								[107.530213, 31.721957],
								[107.525418, 31.726214],
								[107.520547, 31.72766],
								[107.519099, 31.733824],
								[107.515262, 31.734607],
								[107.499183, 31.731073],
								[107.497509, 31.725692],
								[107.492394, 31.729406],
								[107.488783, 31.723583],
								[107.492977, 31.726154],
								[107.494575, 31.722238],
								[107.493033, 31.716856],
								[107.501289, 31.712498],
								[107.501665, 31.698219],
								[107.504072, 31.694724],
								[107.511952, 31.690505],
								[107.517049, 31.683535],
								[107.526866, 31.686769],
								[107.532902, 31.684539],
								[107.54003, 31.692313],
								[107.551163, 31.694463],
								[107.564083, 31.690043],
								[107.569105, 31.689782],
								[107.577097, 31.693619],
								[107.576289, 31.697496],
								[107.590788, 31.703541],
								[107.592857, 31.708502],
								[107.589453, 31.713041],
								[107.581836, 31.714748],
								[107.587648, 31.721977],
								[107.595603, 31.723122],
								[107.598706, 31.729607],
								[107.60258, 31.73288],
								[107.616515, 31.733743],
								[107.639252, 31.746472],
								[107.649407, 31.753839],
								[107.651062, 31.761908],
								[107.656008, 31.762772],
								[107.660108, 31.757753],
								[107.670677, 31.750246],
								[107.679234, 31.747375],
								[107.684462, 31.747476],
								[107.697796, 31.740349],
								[107.703118, 31.729728],
								[107.70545, 31.728724],
								[107.717392, 31.729186],
								[107.724068, 31.720752],
								[107.732607, 31.717238],
								[107.731666, 31.705449],
								[107.734318, 31.697054],
								[107.73759, 31.677609],
								[107.748611, 31.672405],
								[107.758503, 31.659345],
								[107.767041, 31.65203],
								[107.774714, 31.650061],
								[107.782067, 31.6416],
								[107.787164, 31.641841],
								[107.789195, 31.646323],
								[107.786186, 31.652493],
								[107.799143, 31.659626],
								[107.801118, 31.663143],
								[107.813981, 31.659224],
								[107.821636, 31.660189],
								[107.829948, 31.655306],
								[107.829008, 31.648172],
								[107.834104, 31.645238],
								[107.842116, 31.645177],
								[107.854829, 31.650141],
								[107.870344, 31.654904],
								[107.868256, 31.659807],
								[107.879258, 31.669251],
								[107.883884, 31.67128],
								[107.878205, 31.675097],
								[107.873579, 31.686408],
								[107.867147, 31.689481],
								[107.858327, 31.687271],
								[107.849939, 31.689903],
								[107.846968, 31.69384],
								[107.847833, 31.705811],
								[107.849318, 31.710972],
								[107.85543, 31.716937],
								[107.860771, 31.716555],
								[107.876794, 31.706232],
								[107.883301, 31.705329],
								[107.893814, 31.708643],
								[107.891557, 31.712498],
								[107.877453, 31.723101],
								[107.87356, 31.730671],
								[107.875064, 31.740289],
								[107.877171, 31.744545],
								[107.884166, 31.751651],
								[107.886348, 31.757934],
								[107.894848, 31.774192],
								[107.8953, 31.77951],
								[107.898666, 31.78266],
								[107.905004, 31.783383],
								[107.909781, 31.789182],
								[107.914087, 31.802565],
								[107.923998, 31.810409],
								[107.933213, 31.811111],
								[107.945193, 31.807681],
								[107.952603, 31.800799],
								[107.969886, 31.795262],
								[107.978612, 31.796586],
								[107.982542, 31.80417],
								[107.987789, 31.803648],
								[107.995575, 31.807019],
								[108.000634, 31.806618],
								[108.002345, 31.797348],
								[108.00494, 31.795563],
								[108.015942, 31.805976],
								[108.021603, 31.807761],
								[108.031119, 31.805354],
								[108.044622, 31.805735],
								[108.049342, 31.808022],
								[108.0519, 31.812094],
								[108.06892, 31.807841],
								[108.075596, 31.815545],
								[108.08009, 31.814121],
								[108.087143, 31.802244],
								[108.094346, 31.795362],
								[108.091844, 31.789242],
								[108.094158, 31.782881],
								[108.093274, 31.778928],
								[108.096264, 31.77377],
								[108.107623, 31.768753],
								[108.112776, 31.76805],
								[108.115559, 31.760985],
								[108.108488, 31.754542],
								[108.100288, 31.753618],
								[108.101172, 31.749082],
								[108.111516, 31.742115],
								[108.130454, 31.733041],
								[108.143693, 31.733784],
								[108.154695, 31.731615],
								[108.159585, 31.733703],
								[108.170737, 31.732478],
								[108.178128, 31.730149],
								[108.18802, 31.720531],
								[108.218919, 31.7033],
								[108.22347, 31.705489],
								[108.229939, 31.715651],
								[108.232515, 31.722338],
								[108.239116, 31.729567],
								[108.248764, 31.731936],
								[108.261383, 31.727358],
								[108.268229, 31.721194],
								[108.274284, 31.713844],
								[108.281844, 31.712498],
								[108.290382, 31.716173],
								[108.303942, 31.719808],
								[108.322598, 31.731876],
								[108.327826, 31.736052],
								[108.337304, 31.747034],
								[108.348155, 31.762731],
								[108.357088, 31.770519],
								[108.363388, 31.769696],
								[108.375707, 31.76512],
								[108.386802, 31.771944],
								[108.394588, 31.773208],
								[108.408693, 31.767528],
								[108.410893, 31.76797],
								[108.41204, 31.776219],
								[108.422008, 31.784105],
								[108.44781, 31.7826],
								[108.45537, 31.78559]
							]
						]
					]
				}
			},
			{
				"type": "Feature",
				"properties": {
					"adcode": 500154,
					"name": "开州区",
					"center": [108.413317, 31.167735],
					"centroid": [108.382659, 31.271013],
					"childrenNum": 0,
					"level": "district",
					"acroutes": [100000, 500000],
					"parent": {
						"adcode": 500000
					}
				},
				"geometry": {
					"type": "MultiPolygon",
					"coordinates": [
						[
							[
								[108.019926, 31.242545],
								[108.026986, 31.241862],
								[108.028679, 31.24091],
								[108.031651, 31.236836],
								[108.031767, 31.234777],
								[108.028525, 31.226627],
								[108.02791, 31.221999],
								[108.028968, 31.21942],
								[108.031594, 31.217067],
								[108.035364, 31.218235],
								[108.040068, 31.218624],
								[108.066636, 31.228531],
								[108.071022, 31.229552],
								[108.076284, 31.231836],
								[108.078535, 31.229898],
								[108.078179, 31.227397],
								[108.076236, 31.22501],
								[108.069069, 31.222656],
								[108.069849, 31.218936],
								[108.076755, 31.218088],
								[108.080718, 31.218944],
								[108.083921, 31.21486],
								[108.086518, 31.208829],
								[108.089664, 31.205048],
								[108.089904, 31.201881],
								[108.087115, 31.194343],
								[108.085682, 31.188094],
								[108.083777, 31.185714],
								[108.078689, 31.186683],
								[108.070281, 31.177785],
								[108.068079, 31.173067],
								[108.071888, 31.172539],
								[108.073013, 31.171319],
								[108.069454, 31.169371],
								[108.061288, 31.162549],
								[108.055266, 31.156645],
								[108.05465, 31.154896],
								[108.058229, 31.147952],
								[108.056622, 31.145657],
								[108.052419, 31.144973],
								[108.045637, 31.145899],
								[108.041703, 31.144263],
								[108.036288, 31.139933],
								[108.033854, 31.135603],
								[108.035672, 31.131377],
								[108.035335, 31.128978],
								[108.032017, 31.128485],
								[108.02714, 31.122457],
								[108.027496, 31.118628],
								[108.025726, 31.116342],
								[108.02083, 31.113786],
								[108.017838, 31.11305],
								[108.01477, 31.115276],
								[108.012615, 31.114168],
								[108.010287, 31.1113],
								[108.009422, 31.108286],
								[108.012673, 31.104526],
								[108.013856, 31.098722],
								[108.015414, 31.095967],
								[108.018194, 31.093845],
								[108.021753, 31.093281],
								[108.024726, 31.089253],
								[108.024581, 31.086549],
								[108.026476, 31.080476],
								[108.026553, 31.071628],
								[108.027361, 31.069392],
								[108.024072, 31.066221],
								[108.024966, 31.063335],
								[108.028968, 31.061731],
								[108.044935, 31.062],
								[108.050245, 31.059955],
								[108.054535, 31.055708],
								[108.058864, 31.053914],
								[108.060268, 31.05205],
								[108.059422, 31.049459],
								[108.05365, 31.043252],
								[108.053275, 31.040756],
								[108.046801, 31.038502],
								[108.043338, 31.035416],
								[108.038394, 31.034583],
								[108.033614, 31.036127],
								[108.028073, 31.03337],
								[108.023225, 31.032347],
								[108.022513, 31.029928],
								[108.020272, 31.027969],
								[108.016723, 31.027015],
								[108.016588, 31.025914],
								[108.011057, 31.023668],
								[108.003804, 31.025402],
								[108.002159, 31.022827],
								[108.001678, 31.018839],
								[107.997792, 31.013012],
								[107.995897, 31.004297],
								[107.990107, 30.997759],
								[107.987433, 30.989623],
								[107.984614, 30.985556],
								[107.981132, 30.98337],
								[107.976505, 30.982502],
								[107.971542, 30.982433],
								[107.964837, 30.985451],
								[107.957488, 30.985781],
								[107.951909, 30.986518],
								[107.943521, 30.989424],
								[107.941992, 30.987819],
								[107.941184, 30.976136],
								[107.937385, 30.968684],
								[107.937115, 30.956946],
								[107.936307, 30.952764],
								[107.937971, 30.948469],
								[107.93876, 30.940052],
								[107.937115, 30.935661],
								[107.942781, 30.934775],
								[107.94357, 30.930497],
								[107.944512, 30.929881],
								[107.94406, 30.925446],
								[107.948273, 30.919041],
								[107.950717, 30.918381],
								[107.957334, 30.919856],
								[107.958662, 30.919561],
								[107.963798, 30.915898],
								[107.966146, 30.913407],
								[107.971513, 30.91181],
								[107.976332, 30.912904],
								[107.982459, 30.913242],
								[107.986557, 30.911428],
								[107.992973, 30.910187],
								[107.994714, 30.908685],
								[108.000841, 30.911923],
								[108.004872, 30.910551],
								[108.009393, 30.907661],
								[108.012077, 30.902826],
								[108.016848, 30.900195],
								[108.02132, 30.895021],
								[108.025293, 30.888223],
								[108.029439, 30.884489],
								[108.030786, 30.878646],
								[108.036288, 30.870118],
								[108.038567, 30.86918],
								[108.040953, 30.870005],
								[108.041665, 30.872776],
								[108.041097, 30.876527],
								[108.044214, 30.877664],
								[108.047927, 30.876414],
								[108.049851, 30.880547],
								[108.058748, 30.885167],
								[108.063192, 30.88686],
								[108.065741, 30.886851],
								[108.069137, 30.888266],
								[108.069685, 30.891557],
								[108.071763, 30.892703],
								[108.077149, 30.889343],
								[108.078948, 30.886139],
								[108.081815, 30.885705],
								[108.088192, 30.889013],
								[108.090087, 30.887693],
								[108.090655, 30.883508],
								[108.094146, 30.880964],
								[108.097378, 30.88073],
								[108.101649, 30.878003],
								[108.100803, 30.874434],
								[108.097802, 30.872359],
								[108.091665, 30.87288],
								[108.090135, 30.871542],
								[108.093665, 30.863405],
								[108.095253, 30.854833],
								[108.094522, 30.850881],
								[108.096628, 30.847824],
								[108.101226, 30.846278],
								[108.103429, 30.844297],
								[108.105564, 30.839424],
								[108.104698, 30.832735],
								[108.107007, 30.829616],
								[108.109633, 30.82932],
								[108.121214, 30.832561],
								[108.122869, 30.833499],
								[108.123927, 30.837269],
								[108.122657, 30.841083],
								[108.126043, 30.840857],
								[108.12738, 30.836019],
								[108.131747, 30.831979],
								[108.135701, 30.832431],
								[108.13899, 30.831666],
								[108.144791, 30.831857],
								[108.152197, 30.831275],
								[108.157478, 30.834768],
								[108.15971, 30.830884],
								[108.163307, 30.830589],
								[108.167097, 30.827183],
								[108.17259, 30.827296],
								[108.179583, 30.824203],
								[108.18218, 30.824203],
								[108.188375, 30.828982],
								[108.193386, 30.831006],
								[108.197263, 30.833656],
								[108.20012, 30.839189],
								[108.2049, 30.84203],
								[108.210335, 30.847441],
								[108.216722, 30.852036],
								[108.219916, 30.853044],
								[108.226764, 30.853209],
								[108.22865, 30.853756],
								[108.229958, 30.856171],
								[108.225918, 30.860044],
								[108.225466, 30.861382],
								[108.228746, 30.872602],
								[108.23118, 30.876127],
								[108.230631, 30.878151],
								[108.22813, 30.879931],
								[108.228226, 30.881285],
								[108.231362, 30.884107],
								[108.241376, 30.889239],
								[108.24429, 30.891123],
								[108.245098, 30.889777],
								[108.243088, 30.885965],
								[108.243809, 30.882275],
								[108.24606, 30.879453],
								[108.251129, 30.875077],
								[108.25541, 30.872706],
								[108.260056, 30.872793],
								[108.262384, 30.876232],
								[108.268376, 30.881667],
								[108.27259, 30.882675],
								[108.29202, 30.892981],
								[108.296176, 30.895907],
								[108.300283, 30.901029],
								[108.311374, 30.906393],
								[108.319271, 30.908954],
								[108.327024, 30.912235],
								[108.336624, 30.918251],
								[108.342838, 30.919709],
								[108.346291, 30.921236],
								[108.348869, 30.925897],
								[108.350927, 30.926999],
								[108.359075, 30.929178],
								[108.362383, 30.927659],
								[108.36272, 30.93028],
								[108.360844, 30.932979],
								[108.356083, 30.936485],
								[108.349003, 30.939167],
								[108.341876, 30.954751],
								[108.339663, 30.963123],
								[108.346656, 30.962793],
								[108.351379, 30.960893],
								[108.355208, 30.960425],
								[108.358536, 30.96334],
								[108.364423, 30.965292],
								[108.372791, 30.969777],
								[108.379005, 30.975485],
								[108.380996, 30.976448],
								[108.386066, 30.981314],
								[108.387884, 30.984827],
								[108.392328, 30.989034],
								[108.40035, 30.994324],
								[108.405313, 30.995061],
								[108.410873, 30.997186],
								[108.413326, 30.997316],
								[108.41499, 30.998903],
								[108.415077, 31.00219],
								[108.416337, 31.004636],
								[108.419299, 31.006734],
								[108.423945, 31.00859],
								[108.432141, 31.012726],
								[108.438336, 31.013966],
								[108.442251, 31.013177],
								[108.448311, 31.012934],
								[108.451244, 31.013515],
								[108.466577, 31.019836],
								[108.472349, 31.020885],
								[108.47434, 31.022533],
								[108.476514, 31.026807],
								[108.479601, 31.035867],
								[108.480005, 31.038753],
								[108.479092, 31.042099],
								[108.480448, 31.047664],
								[108.476716, 31.052787],
								[108.478909, 31.054997],
								[108.4862, 31.05732],
								[108.488133, 31.059565],
								[108.487123, 31.063681],
								[108.497589, 31.069444],
								[108.502754, 31.070172],
								[108.508507, 31.072165],
								[108.511768, 31.073864],
								[108.51096, 31.077911],
								[108.515731, 31.080233],
								[108.520694, 31.080406],
								[108.52762, 31.083378],
								[108.530313, 31.083829],
								[108.539932, 31.08298],
								[108.53999, 31.084877],
								[108.537652, 31.086047],
								[108.535267, 31.091722],
								[108.537922, 31.095482],
								[108.540923, 31.095274],
								[108.546387, 31.092944],
								[108.554486, 31.0905],
								[108.556708, 31.08888],
								[108.558872, 31.089391],
								[108.552793, 31.096782],
								[108.547541, 31.105245],
								[108.549282, 31.109663],
								[108.550956, 31.110668],
								[108.555736, 31.111292],
								[108.561036, 31.113249],
								[108.562614, 31.115779],
								[108.564586, 31.126397],
								[108.562729, 31.130459],
								[108.564393, 31.135915],
								[108.566923, 31.139795],
								[108.569395, 31.141423],
								[108.572002, 31.14112],
								[108.576946, 31.142869],
								[108.584497, 31.147008],
								[108.590317, 31.151216],
								[108.593703, 31.156385],
								[108.60184, 31.160082],
								[108.602389, 31.162471],
								[108.600311, 31.168367],
								[108.598426, 31.170938],
								[108.591654, 31.173812],
								[108.586835, 31.173466],
								[108.583593, 31.174877],
								[108.583439, 31.177837],
								[108.57963, 31.176712],
								[108.578043, 31.180053],
								[108.585815, 31.183965],
								[108.58873, 31.186121],
								[108.590172, 31.190941],
								[108.590605, 31.195329],
								[108.588768, 31.201604],
								[108.591057, 31.205368],
								[108.591875, 31.208751],
								[108.592587, 31.216029],
								[108.596656, 31.230633],
								[108.599032, 31.235434],
								[108.602312, 31.236706],
								[108.606063, 31.239716],
								[108.618626, 31.252224],
								[108.619212, 31.257353],
								[108.622771, 31.259489],
								[108.629986, 31.25686],
								[108.631284, 31.25724],
								[108.636729, 31.264773],
								[108.642135, 31.266347],
								[108.643491, 31.268708],
								[108.643308, 31.276058],
								[108.642404, 31.278056],
								[108.639085, 31.27969],
								[108.636344, 31.283503],
								[108.637941, 31.284696],
								[108.651369, 31.288155],
								[108.656034, 31.289901],
								[108.658708, 31.293135],
								[108.659045, 31.298547],
								[108.65084, 31.309499],
								[108.639797, 31.319569],
								[108.638104, 31.321981],
								[108.635786, 31.329906],
								[108.636633, 31.332887],
								[108.639018, 31.337389],
								[108.644164, 31.339489],
								[108.655322, 31.340025],
								[108.659035, 31.340958],
								[108.664509, 31.339947],
								[108.66901, 31.341598],
								[108.679995, 31.340932],
								[108.68441, 31.341027],
								[108.688364, 31.342324],
								[108.698214, 31.343965],
								[108.701042, 31.346506],
								[108.701523, 31.348986],
								[108.697396, 31.35677],
								[108.696309, 31.360615],
								[108.696761, 31.362438],
								[108.701917, 31.365651],
								[108.704774, 31.371137],
								[108.707862, 31.370325],
								[108.708102, 31.372407],
								[108.710805, 31.373383],
								[108.70917, 31.374964],
								[108.702927, 31.375698],
								[108.699397, 31.377037],
								[108.693472, 31.377408],
								[108.691779, 31.379896],
								[108.692308, 31.383126],
								[108.694568, 31.387186],
								[108.698704, 31.390286],
								[108.707535, 31.393732],
								[108.712508, 31.394587],
								[108.715826, 31.394164],
								[108.729957, 31.39077],
								[108.732765, 31.391055],
								[108.736257, 31.393896],
								[108.736392, 31.395727],
								[108.731525, 31.403283],
								[108.732208, 31.404924],
								[108.737229, 31.405598],
								[108.740009, 31.407661],
								[108.739768, 31.414146],
								[108.742086, 31.420078],
								[108.7478, 31.424731],
								[108.754976, 31.428055],
								[108.757785, 31.430947],
								[108.759901, 31.438587],
								[108.75665, 31.440287],
								[108.752283, 31.443749],
								[108.749743, 31.443637],
								[108.74427, 31.442117],
								[108.740057, 31.441841],
								[108.738854, 31.445259],
								[108.736805, 31.448323],
								[108.732814, 31.451767],
								[108.730043, 31.452432],
								[108.7266, 31.455116],
								[108.716894, 31.458111],
								[108.713518, 31.458396],
								[108.706159, 31.463047],
								[108.703091, 31.463833],
								[108.70539, 31.46712],
								[108.703235, 31.471237],
								[108.696646, 31.477803],
								[108.696665, 31.482204],
								[108.699637, 31.487631],
								[108.699705, 31.491634],
								[108.697973, 31.494826],
								[108.695174, 31.497517],
								[108.694568, 31.499346],
								[108.696146, 31.501934],
								[108.699099, 31.502107],
								[108.701532, 31.503711],
								[108.703995, 31.503978],
								[108.705265, 31.50265],
								[108.708814, 31.502581],
								[108.712796, 31.501253],
								[108.718481, 31.50171],
								[108.721732, 31.503254],
								[108.727109, 31.501943],
								[108.730053, 31.499743],
								[108.733314, 31.501287],
								[108.748031, 31.505221],
								[108.750984, 31.505341],
								[108.761065, 31.503909],
								[108.763864, 31.505497],
								[108.76547, 31.508283],
								[108.766894, 31.513605],
								[108.767702, 31.522885],
								[108.769491, 31.529845],
								[108.777109, 31.543624],
								[108.779206, 31.54528],
								[108.784391, 31.546314],
								[108.786055, 31.548875],
								[108.792923, 31.550462],
								[108.794539, 31.552117],
								[108.79553, 31.55798],
								[108.799435, 31.566781],
								[108.800705, 31.572264],
								[108.801955, 31.575005],
								[108.804389, 31.576237],
								[108.812248, 31.574315],
								[108.820289, 31.574582],
								[108.822704, 31.576875],
								[108.82306, 31.578849],
								[108.826455, 31.578203],
								[108.837075, 31.574229],
								[108.840682, 31.574272],
								[108.844222, 31.576789],
								[108.850205, 31.577504],
								[108.853398, 31.578918],
								[108.859814, 31.584908],
								[108.865143, 31.592811],
								[108.873396, 31.598438],
								[108.877984, 31.602816],
								[108.881341, 31.604841],
								[108.883948, 31.605384],
								[108.88719, 31.603238],
								[108.891182, 31.603902],
								[108.894866, 31.606021],
								[108.896549, 31.609468],
								[108.895837, 31.614595],
								[108.89174, 31.618808],
								[108.888652, 31.619781],
								[108.88769, 31.62159],
								[108.887729, 31.625657],
								[108.892471, 31.630162],
								[108.893269, 31.632178],
								[108.891922, 31.638346],
								[108.89273, 31.642592],
								[108.895866, 31.645744],
								[108.896645, 31.647872],
								[108.895241, 31.651661],
								[108.898204, 31.654839],
								[108.891615, 31.654202],
								[108.888421, 31.654977],
								[108.882323, 31.661117],
								[108.878283, 31.664278],
								[108.873646, 31.664588],
								[108.865383, 31.671072],
								[108.864652, 31.674258],
								[108.862017, 31.680052],
								[108.860535, 31.681068],
								[108.856332, 31.681344],
								[108.850445, 31.680948],
								[108.846944, 31.682058],
								[108.84604, 31.685261],
								[108.843154, 31.686518],
								[108.839893, 31.684245],
								[108.831274, 31.685339],
								[108.829062, 31.684237],
								[108.809901, 31.685769],
								[108.806736, 31.68558],
								[108.800772, 31.684176],
								[108.794501, 31.684237],
								[108.78291, 31.688817],
								[108.781669, 31.688352],
								[108.779687, 31.683074],
								[108.7766, 31.681628],
								[108.770626, 31.682153],
								[108.767567, 31.67926],
								[108.768173, 31.676126],
								[108.764364, 31.670693],
								[108.758141, 31.664295],
								[108.755226, 31.655416],
								[108.755793, 31.649241],
								[108.755351, 31.647071],
								[108.748098, 31.64248],
								[108.742298, 31.640921],
								[108.741807, 31.638837],
								[108.73845, 31.636855],
								[108.736209, 31.633289],
								[108.728995, 31.634443],
								[108.726686, 31.633746],
								[108.722896, 31.629378],
								[108.714701, 31.627664],
								[108.711286, 31.628396],
								[108.709699, 31.630481],
								[108.701888, 31.633858],
								[108.69956, 31.63409],
								[108.696348, 31.632118],
								[108.695174, 31.629585],
								[108.696223, 31.626777],
								[108.691529, 31.625243],
								[108.683901, 31.62744],
								[108.681053, 31.626622],
								[108.680832, 31.624614],
								[108.676715, 31.622745],
								[108.673329, 31.6229],
								[108.665115, 31.624683],
								[108.661536, 31.624476],
								[108.657342, 31.626294],
								[108.653062, 31.625131],
								[108.651417, 31.622641],
								[108.649387, 31.621737],
								[108.646434, 31.622435],
								[108.64123, 31.625192],
								[108.639259, 31.627733],
								[108.638932, 31.629835],
								[108.640095, 31.636993],
								[108.638662, 31.640499],
								[108.635026, 31.643884],
								[108.631005, 31.645107],
								[108.626302, 31.650223],
								[108.623743, 31.651524],
								[108.621925, 31.648897],
								[108.617462, 31.648673],
								[108.614922, 31.649388],
								[108.611892, 31.652092],
								[108.608708, 31.650421],
								[108.598262, 31.65583],
								[108.592596, 31.656183],
								[108.590846, 31.65837],
								[108.586979, 31.659068],
								[108.583256, 31.66445],
								[108.579515, 31.66346],
								[108.576812, 31.664106],
								[108.576052, 31.666078],
								[108.577523, 31.667722],
								[108.574493, 31.670891],
								[108.571435, 31.671296],
								[108.565307, 31.673603],
								[108.563643, 31.673018],
								[108.561806, 31.669746],
								[108.558843, 31.670073],
								[108.548993, 31.674361],
								[108.542847, 31.673621],
								[108.546463, 31.666689],
								[108.546098, 31.664734],
								[108.541452, 31.664588],
								[108.534882, 31.668583],
								[108.532804, 31.669126],
								[108.530505, 31.666336],
								[108.527418, 31.665475],
								[108.523686, 31.666103],
								[108.519049, 31.665716],
								[108.517462, 31.6642],
								[108.516173, 31.658861],
								[108.513268, 31.656191],
								[108.506188, 31.657165],
								[108.501937, 31.653832],
								[108.502341, 31.652299],
								[108.496223, 31.650783],
								[108.492558, 31.646218],
								[108.492125, 31.643384],
								[108.490153, 31.641283],
								[108.486162, 31.639862],
								[108.481352, 31.63919],
								[108.476543, 31.640025],
								[108.473003, 31.63944],
								[108.470588, 31.63807],
								[108.467837, 31.634297],
								[108.467655, 31.630567],
								[108.468665, 31.626544],
								[108.46827, 31.622288],
								[108.465269, 31.61836],
								[108.462999, 31.618653],
								[108.460315, 31.621272],
								[108.456468, 31.628879],
								[108.454544, 31.630662],
								[108.450898, 31.632264],
								[108.442664, 31.633703],
								[108.434122, 31.629861],
								[108.423089, 31.62128],
								[108.420867, 31.616981],
								[108.421521, 31.614491],
								[108.418366, 31.610459],
								[108.415721, 31.609451],
								[108.410912, 31.606357],
								[108.404342, 31.60466],
								[108.399734, 31.601653],
								[108.391827, 31.593216],
								[108.388903, 31.588812],
								[108.388442, 31.58552],
								[108.38925, 31.582917],
								[108.393184, 31.578659],
								[108.393607, 31.576332],
								[108.384478, 31.573272],
								[108.377928, 31.570419],
								[108.379188, 31.569083],
								[108.38773, 31.564066],
								[108.390712, 31.562911],
								[108.391799, 31.560437],
								[108.391173, 31.555307],
								[108.388653, 31.546383],
								[108.386325, 31.544271],
								[108.381141, 31.542607],
								[108.37585, 31.54271],
								[108.365471, 31.544038],
								[108.359815, 31.54515],
								[108.353842, 31.544245],
								[108.35034, 31.54603],
								[108.347589, 31.545168],
								[108.345743, 31.541727],
								[108.341933, 31.540572],
								[108.339077, 31.537899],
								[108.341039, 31.534475],
								[108.340789, 31.530112],
								[108.342905, 31.526593],
								[108.345973, 31.525645],
								[108.347118, 31.52342],
								[108.345329, 31.514916],
								[108.341577, 31.509904],
								[108.339057, 31.50849],
								[108.334681, 31.509163],
								[108.330814, 31.507222],
								[108.323272, 31.502383],
								[108.320723, 31.502098],
								[108.31779, 31.503185],
								[108.314, 31.506204],
								[108.311422, 31.506687],
								[108.303149, 31.50428],
								[108.295974, 31.503504],
								[108.291568, 31.504531],
								[108.287374, 31.506696],
								[108.281728, 31.507291],
								[108.278601, 31.501882],
								[108.277014, 31.501184],
								[108.263326, 31.502771],
								[108.259575, 31.502503],
								[108.254804, 31.498837],
								[108.249196, 31.498561],
								[108.247676, 31.501011],
								[108.236662, 31.506376],
								[108.234123, 31.506756],
								[108.226745, 31.505842],
								[108.224648, 31.504634],
								[108.225591, 31.50133],
								[108.222022, 31.498397],
								[108.212211, 31.493713],
								[108.208113, 31.492729],
								[108.194406, 31.491987],
								[108.1905, 31.491487],
								[108.189, 31.488364],
								[108.188981, 31.482488],
								[108.189664, 31.480642],
								[108.193646, 31.477303],
								[108.194165, 31.475983],
								[108.1933, 31.468199],
								[108.194925, 31.467224],
								[108.200389, 31.466939],
								[108.20263, 31.46435],
								[108.204612, 31.463548],
								[108.206834, 31.46429],
								[108.210816, 31.467871],
								[108.213625, 31.467845],
								[108.223128, 31.465179],
								[108.224398, 31.46265],
								[108.225572, 31.45528],
								[108.225187, 31.451198],
								[108.223869, 31.449247],
								[108.213269, 31.440952],
								[108.210345, 31.437137],
								[108.211239, 31.434357],
								[108.216568, 31.427036],
								[108.216155, 31.416615],
								[108.216318, 31.411184],
								[108.214009, 31.409578],
								[108.211634, 31.404302],
								[108.208055, 31.399138],
								[108.203409, 31.395382],
								[108.196772, 31.394],
								[108.189375, 31.393853],
								[108.182844, 31.393015],
								[108.178313, 31.390718],
								[108.176216, 31.387272],
								[108.175245, 31.382625],
								[108.172013, 31.379283],
								[108.167309, 31.377408],
								[108.164183, 31.379205],
								[108.160595, 31.378738],
								[108.157805, 31.376639],
								[108.15414, 31.370765],
								[108.15769, 31.367267],
								[108.159181, 31.362023],
								[108.158892, 31.360295],
								[108.171166, 31.356978],
								[108.174523, 31.355656],
								[108.178323, 31.352926],
								[108.180602, 31.349236],
								[108.178073, 31.344726],
								[108.182295, 31.341408],
								[108.185527, 31.33802],
								[108.184844, 31.333743],
								[108.180458, 31.325792],
								[108.176841, 31.323536],
								[108.165674, 31.321436],
								[108.162855, 31.319707],
								[108.161374, 31.31638],
								[108.161403, 31.313769],
								[108.158623, 31.311557],
								[108.154487, 31.310398],
								[108.151774, 31.308687],
								[108.147773, 31.30401],
								[108.144339, 31.301261],
								[108.138452, 31.298962],
								[108.12334, 31.291492],
								[108.119031, 31.290472],
								[108.117511, 31.287169],
								[108.111932, 31.286581],
								[108.106776, 31.283745],
								[108.101139, 31.279189],
								[108.099822, 31.276456],
								[108.094502, 31.275047],
								[108.093771, 31.271873],
								[108.095368, 31.268985],
								[108.0943, 31.267238],
								[108.090154, 31.264713],
								[108.080583, 31.26147],
								[108.078198, 31.261098],
								[108.073802, 31.262265],
								[108.06982, 31.262482],
								[108.066405, 31.261746],
								[108.063808, 31.259558],
								[108.059951, 31.254974],
								[108.05416, 31.254136],
								[108.047109, 31.254308],
								[108.040857, 31.253599],
								[108.035932, 31.251809],
								[108.028641, 31.247735],
								[108.019483, 31.24501],
								[108.019926, 31.242545]
							]
						]
					]
				}
			}, {
				"type": "Feature",
				"properties": {
					"adcode": 500101,
					"name": "万州区",
					"center": [108.380246, 30.807807],
					"centroid": [108.406819, 30.704054],
					"childrenNum": 0,
					"level": "district",
					"acroutes": [100000, 500000],
					"parent": {
						"adcode": 500000
					}
				},
				"geometry": {
					"type": "MultiPolygon",
					"coordinates": [
						[
							[
								[108.034787, 30.574187],
								[108.037722, 30.575399],
								[108.040625, 30.575977],
								[108.042609, 30.575755],
								[108.049575, 30.572821],
								[108.052861, 30.572108],
								[108.058544, 30.574119],
								[108.062668, 30.574445],
								[108.066119, 30.578609],
								[108.068424, 30.579673],
								[108.070935, 30.581531],
								[108.072516, 30.582146],
								[108.076174, 30.582219],
								[108.081579, 30.580399],
								[108.083356, 30.579569],
								[108.084234, 30.578216],
								[108.084513, 30.574605],
								[108.086487, 30.572766],
								[108.088585, 30.5726],
								[108.092956, 30.574654],
								[108.095622, 30.57479],
								[108.097741, 30.574039],
								[108.099281, 30.572846],
								[108.102288, 30.572551],
								[108.103858, 30.573381],
								[108.103641, 30.574894],
								[108.102143, 30.577613],
								[108.102515, 30.579353],
								[108.103435, 30.581162],
								[108.103466, 30.583511],
								[108.103104, 30.586838],
								[108.104106, 30.589378],
								[108.105646, 30.591463],
								[108.10823, 30.593025],
								[108.111599, 30.593449],
								[108.11256, 30.592176],
								[108.113314, 30.590024],
								[108.114657, 30.589089],
								[108.11659, 30.589434],
								[108.11969, 30.590584],
								[108.123265, 30.588972],
								[108.126035, 30.587226],
								[108.127172, 30.586106],
								[108.12742, 30.58479],
								[108.12651, 30.582662],
								[108.126758, 30.579058],
								[108.125983, 30.577176],
								[108.123772, 30.57677],
								[108.120062, 30.576955],
								[108.119742, 30.575602],
								[108.121033, 30.573578],
								[108.122687, 30.570115],
								[108.123069, 30.566879],
								[108.124681, 30.564886],
								[108.126469, 30.564007],
								[108.130427, 30.564154],
								[108.132401, 30.564437],
								[108.137051, 30.567353],
								[108.1395, 30.568079],
								[108.141629, 30.567636],
								[108.143592, 30.566234],
								[108.14599, 30.560894],
								[108.148563, 30.554182],
								[108.149824, 30.549605],
								[108.153068, 30.545883],
								[108.15312, 30.544542],
								[108.152521, 30.53594],
								[108.153378, 30.535337],
								[108.155745, 30.536112],
								[108.159382, 30.53794],
								[108.163351, 30.540813],
								[108.165252, 30.541268],
								[108.168228, 30.54133],
								[108.17139, 30.539318],
								[108.171638, 30.538266],
								[108.171122, 30.53642],
								[108.170863, 30.532894],
								[108.16766, 30.527971],
								[108.165624, 30.524408],
								[108.16581, 30.521829],
								[108.168383, 30.519497],
								[108.17045, 30.518198],
								[108.171204, 30.516654],
								[108.171401, 30.514075],
								[108.173519, 30.511114],
								[108.175555, 30.510641],
								[108.178345, 30.513176],
								[108.180526, 30.515909],
								[108.184514, 30.518161],
								[108.186209, 30.517626],
								[108.18994, 30.516998],
								[108.193526, 30.51557],
								[108.195034, 30.514481],
								[108.198941, 30.510099],
								[108.204903, 30.502313],
								[108.207817, 30.497659],
								[108.213501, 30.492082],
								[108.222522, 30.48403],
								[108.23049, 30.476703],
								[108.231771, 30.474185],
								[108.231864, 30.471045],
								[108.230728, 30.464031],
								[108.230893, 30.46187],
								[108.231658, 30.457565],
								[108.232763, 30.455028],
								[108.234851, 30.452632],
								[108.239801, 30.448137],
								[108.241692, 30.445648],
								[108.241919, 30.443764],
								[108.23885, 30.440044],
								[108.23235, 30.432806],
								[108.227038, 30.428131],
								[108.225974, 30.426948],
								[108.224868, 30.424946],
								[108.223628, 30.421287],
								[108.228041, 30.419371],
								[108.232939, 30.415884],
								[108.235109, 30.413765],
								[108.235864, 30.410647],
								[108.236752, 30.409212],
								[108.239356, 30.408565],
								[108.243893, 30.408614],
								[108.248884, 30.40793],
								[108.252077, 30.406334],
								[108.254268, 30.403512],
								[108.255684, 30.400339],
								[108.257286, 30.395169],
								[108.259942, 30.390122],
								[108.261492, 30.388588],
								[108.263662, 30.389857],
								[108.26702, 30.394226],
								[108.275039, 30.405558],
								[108.276899, 30.40708],
								[108.278532, 30.407061],
								[108.280764, 30.406488],
								[108.292845, 30.401306],
								[108.295666, 30.400838],
								[108.296896, 30.401393],
								[108.297733, 30.402508],
								[108.298528, 30.404942],
								[108.298952, 30.407579],
								[108.299892, 30.409359],
								[108.30168, 30.41122],
								[108.305886, 30.414541],
								[108.307684, 30.416346],
								[108.311931, 30.423295],
								[108.31344, 30.424305],
								[108.315848, 30.425377],
								[108.31715, 30.427398],
								[108.317543, 30.429769],
								[108.317067, 30.433114],
								[108.317429, 30.433946],
								[108.320064, 30.436841],
								[108.332454, 30.449325],
								[108.333188, 30.450348],
								[108.333984, 30.452639],
								[108.334408, 30.457639],
								[108.334873, 30.460072],
								[108.337291, 30.466987],
								[108.338841, 30.470355],
								[108.340608, 30.47267],
								[108.34347, 30.475478],
								[108.344648, 30.477005],
								[108.346808, 30.481456],
								[108.349206, 30.487637],
								[108.350652, 30.490678],
								[108.35368, 30.49516],
								[108.356005, 30.497333],
								[108.37873, 30.534869],
								[108.381509, 30.537392],
								[108.383338, 30.538056],
								[108.388402, 30.536869],
								[108.389983, 30.535977],
								[108.393094, 30.536131],
								[108.395501, 30.537429],
								[108.396773, 30.538955],
								[108.398498, 30.542277],
								[108.399614, 30.543865],
								[108.402704, 30.547132],
								[108.404316, 30.548307],
								[108.406094, 30.548233],
								[108.408223, 30.546707],
								[108.409618, 30.545071],
								[108.410403, 30.54325],
								[108.410692, 30.535417],
								[108.409731, 30.53274],
								[108.403986, 30.530863],
								[108.402229, 30.529706],
								[108.400668, 30.527706],
								[108.399273, 30.525011],
								[108.399552, 30.520519],
								[108.401247, 30.517743],
								[108.403645, 30.516032],
								[108.404978, 30.51565],
								[108.409938, 30.517546],
								[108.411323, 30.517023],
								[108.412738, 30.5141],
								[108.412811, 30.51045],
								[108.411447, 30.507742],
								[108.411385, 30.50592],
								[108.412346, 30.503064],
								[108.413348, 30.503082],
								[108.415074, 30.504172],
								[108.418949, 30.508462],
								[108.424312, 30.510481],
								[108.426038, 30.511859],
								[108.427795, 30.512561],
								[108.429118, 30.512395],
								[108.429603, 30.511256],
								[108.427692, 30.508154],
								[108.42424, 30.504732],
								[108.422132, 30.50086],
								[108.421016, 30.497197],
								[108.421212, 30.494095],
								[108.422535, 30.492907],
								[108.424064, 30.488949],
								[108.425604, 30.487908],
								[108.426906, 30.48792],
								[108.427351, 30.489219],
								[108.426431, 30.490986],
								[108.427526, 30.492439],
								[108.431122, 30.492045],
								[108.43476, 30.493473],
								[108.436331, 30.492981],
								[108.437912, 30.489515],
								[108.439173, 30.486227],
								[108.440371, 30.48592],
								[108.44096, 30.486806],
								[108.440981, 30.491029],
								[108.442386, 30.49218],
								[108.44375, 30.492556],
								[108.445332, 30.491891],
								[108.446789, 30.492131],
								[108.448297, 30.493276],
								[108.450891, 30.494218],
								[108.4527, 30.495966],
								[108.45519, 30.496053],
								[108.456141, 30.494477],
								[108.455273, 30.491239],
								[108.455087, 30.489306],
								[108.456378, 30.48893],
								[108.457629, 30.489903],
								[108.45921, 30.490389],
								[108.460378, 30.489897],
								[108.461535, 30.488604],
								[108.463519, 30.487988],
								[108.465896, 30.488013],
								[108.4685, 30.487022],
								[108.470257, 30.486954],
								[108.476519, 30.488062],
								[108.479154, 30.488955],
								[108.483288, 30.491756],
								[108.486192, 30.494803],
								[108.488548, 30.49945],
								[108.489695, 30.50054],
								[108.491751, 30.501488],
								[108.496185, 30.502319],
								[108.501372, 30.503014],
								[108.503987, 30.503205],
								[108.507883, 30.503174],
								[108.510879, 30.502528],
								[108.513111, 30.501426],
								[108.515819, 30.499155],
								[108.518857, 30.495843],
								[108.520728, 30.494594],
								[108.525471, 30.490943],
								[108.526287, 30.490112],
								[108.527093, 30.487859],
								[108.528313, 30.48749],
								[108.529584, 30.489423],
								[108.529718, 30.490783],
								[108.532157, 30.490912],
								[108.533903, 30.488782],
								[108.536652, 30.486609],
								[108.537851, 30.486024],
								[108.539153, 30.486141],
								[108.537427, 30.489078],
								[108.535464, 30.490007],
								[108.535546, 30.491115],
								[108.537685, 30.491245],
								[108.541457, 30.491934],
								[108.543948, 30.491706],
								[108.545281, 30.489355],
								[108.546769, 30.488721],
								[108.555036, 30.487994],
								[108.556173, 30.487477],
								[108.557072, 30.486012],
								[108.555997, 30.485501],
								[108.552267, 30.48515],
								[108.551471, 30.484559],
								[108.552504, 30.482848],
								[108.554034, 30.482213],
								[108.557609, 30.481573],
								[108.562063, 30.477146],
								[108.5635, 30.474536],
								[108.563386, 30.470478],
								[108.564946, 30.468576],
								[108.567602, 30.468619],
								[108.569204, 30.469302],
								[108.569927, 30.470429],
								[108.57125, 30.474733],
								[108.573348, 30.476124],
								[108.575993, 30.479117],
								[108.577078, 30.479541],
								[108.579879, 30.479554],
								[108.580612, 30.480594],
								[108.581408, 30.482915],
								[108.581698, 30.485901],
								[108.58488, 30.487687],
								[108.587671, 30.487982],
								[108.590461, 30.487477],
								[108.591256, 30.487779],
								[108.591349, 30.489798],
								[108.590709, 30.492753],
								[108.590595, 30.494698],
								[108.592238, 30.495209],
								[108.593954, 30.495049],
								[108.598552, 30.493892],
								[108.600888, 30.495652],
								[108.60561, 30.498699],
								[108.611397, 30.502177],
								[108.612007, 30.504387],
								[108.61055, 30.505507],
								[108.607171, 30.505821],
								[108.605558, 30.506652],
								[108.604918, 30.507975],
								[108.604535, 30.510813],
								[108.606179, 30.511607],
								[108.60933, 30.511188],
								[108.611201, 30.511558],
								[108.621731, 30.51546],
								[108.622764, 30.517878],
								[108.62417, 30.519195],
								[108.624149, 30.519817],
								[108.620295, 30.521472],
								[108.619726, 30.522199],
								[108.624366, 30.526057],
								[108.627725, 30.525128],
								[108.628593, 30.525183],
								[108.630918, 30.528396],
								[108.633264, 30.529756],
								[108.640497, 30.531577],
								[108.642688, 30.532402],
								[108.644424, 30.533663],
								[108.645964, 30.536241],
								[108.649157, 30.537693],
								[108.649622, 30.541471],
								[108.648837, 30.543649],
								[108.648299, 30.547009],
								[108.648744, 30.548165],
								[108.650201, 30.550103],
								[108.649746, 30.554219],
								[108.646801, 30.557566],
								[108.645437, 30.55855],
								[108.644796, 30.560402],
								[108.643598, 30.561712],
								[108.642771, 30.563601],
								[108.64183, 30.567224],
								[108.641634, 30.569334],
								[108.640942, 30.571191],
								[108.64089, 30.572618],
								[108.639888, 30.574753],
								[108.641861, 30.577588],
								[108.645892, 30.580337],
								[108.651586, 30.583548],
								[108.654479, 30.584999],
								[108.659078, 30.586949],
								[108.662209, 30.587982],
								[108.666198, 30.588591],
								[108.669556, 30.58856],
								[108.676273, 30.588124],
								[108.683838, 30.587269],
								[108.6873, 30.587595],
								[108.690462, 30.586715],
								[108.691485, 30.585381],
								[108.691929, 30.583671],
								[108.693035, 30.58281],
								[108.693655, 30.581592],
								[108.693107, 30.578917],
								[108.693531, 30.576543],
								[108.694337, 30.574058],
								[108.694781, 30.571148],
								[108.696879, 30.568337],
								[108.697127, 30.566332],
								[108.70001, 30.561805],
								[108.699328, 30.560439],
								[108.697292, 30.558667],
								[108.697261, 30.557504],
								[108.698708, 30.555683],
								[108.699111, 30.55409],
								[108.698377, 30.551998],
								[108.69845, 30.550307],
								[108.69938, 30.548479],
								[108.699411, 30.544406],
								[108.702408, 30.541742],
								[108.705188, 30.540352],
								[108.706004, 30.538629],
								[108.708164, 30.53781],
								[108.710685, 30.537983],
								[108.71206, 30.537595],
								[108.712969, 30.536734],
								[108.713827, 30.534549],
								[108.715387, 30.533405],
								[108.715811, 30.530377],
								[108.713558, 30.5297],
								[108.711946, 30.528149],
								[108.711212, 30.526426],
								[108.711884, 30.523202],
								[108.713982, 30.520759],
								[108.716699, 30.520143],
								[108.718456, 30.519122],
								[108.71949, 30.519005],
								[108.721091, 30.517971],
								[108.722559, 30.517614],
								[108.725979, 30.5157],
								[108.726837, 30.513441],
								[108.724626, 30.511188],
								[108.723985, 30.507557],
								[108.725008, 30.506154],
								[108.728263, 30.503408],
								[108.730609, 30.50187],
								[108.73561, 30.498995],
								[108.741304, 30.495948],
								[108.744188, 30.494803],
								[108.746409, 30.494735],
								[108.747959, 30.495246],
								[108.750894, 30.496927],
								[108.752475, 30.498989],
								[108.753726, 30.502134],
								[108.754997, 30.503347],
								[108.758572, 30.504781],
								[108.762789, 30.505273],
								[108.765424, 30.504572],
								[108.768948, 30.503304],
								[108.770622, 30.502984],
								[108.772936, 30.503482],
								[108.78853, 30.51293],
								[108.790008, 30.513293],
								[108.791217, 30.512973],
								[108.793057, 30.51181],
								[108.796715, 30.508542],
								[108.799247, 30.505932],
								[108.800466, 30.504153],
								[108.802481, 30.500441],
								[108.803587, 30.49524],
								[108.804362, 30.493787],
								[108.806945, 30.491399],
								[108.808464, 30.491171],
								[108.811534, 30.492027],
								[108.817631, 30.494483],
								[108.828119, 30.499155],
								[108.839011, 30.503162],
								[108.843744, 30.505926],
								[108.846534, 30.508191],
								[108.851681, 30.513379],
								[108.852983, 30.514998],
								[108.854843, 30.518445],
								[108.854419, 30.521897],
								[108.85475, 30.522789],
								[108.856269, 30.524297],
								[108.859865, 30.52671],
								[108.869372, 30.531048],
								[108.871687, 30.532734],
								[108.873537, 30.535392],
								[108.875831, 30.537398],
								[108.881835, 30.544425],
								[108.888965, 30.553727],
								[108.89312, 30.557806],
								[108.893636, 30.55871],
								[108.893895, 30.560802],
								[108.893729, 30.565225],
								[108.894659, 30.567027],
								[108.909509, 30.58126],
								[108.90831, 30.582121],
								[108.907515, 30.583855],
								[108.904776, 30.585891],
								[108.901314, 30.589077],
								[108.897739, 30.590676],
								[108.894473, 30.592373],
								[108.89312, 30.593634],
								[108.889027, 30.596413],
								[108.885163, 30.599291],
								[108.881391, 30.602347],
								[108.878973, 30.604696],
								[108.876058, 30.606565],
								[108.874477, 30.608724],
								[108.872266, 30.610033],
								[108.869879, 30.610968],
								[108.871077, 30.613452],
								[108.873, 30.614866],
								[108.873103, 30.616003],
								[108.871677, 30.617731],
								[108.871708, 30.61834],
								[108.873072, 30.618899],
								[108.874581, 30.618524],
								[108.876151, 30.618881],
								[108.876957, 30.620565],
								[108.876492, 30.623504],
								[108.878249, 30.624616],
								[108.878921, 30.625907],
								[108.881194, 30.625723],
								[108.882114, 30.626239],
								[108.881887, 30.627942],
								[108.882331, 30.62903],
								[108.884646, 30.629295],
								[108.888521, 30.630924],
								[108.887736, 30.633247],
								[108.890474, 30.633512],
								[108.892427, 30.634876],
								[108.894763, 30.634661],
								[108.895982, 30.63535],
								[108.895744, 30.637003],
								[108.894618, 30.638232],
								[108.894494, 30.639161],
								[108.895579, 30.640826],
								[108.897894, 30.640802],
								[108.899206, 30.641595],
								[108.899392, 30.64393],
								[108.901376, 30.646272],
								[108.90179, 30.647194],
								[108.90056, 30.651865],
								[108.900932, 30.653641],
								[108.900508, 30.655878],
								[108.901903, 30.657101],
								[108.902668, 30.660807],
								[108.90243, 30.662939],
								[108.900601, 30.664076],
								[108.900601, 30.665637],
								[108.900043, 30.669078],
								[108.900436, 30.671173],
								[108.89995, 30.676421],
								[108.898927, 30.679038],
								[108.897264, 30.681754],
								[108.896302, 30.684021],
								[108.893822, 30.68595],
								[108.892582, 30.686472],
								[108.890164, 30.686288],
								[108.889141, 30.687019],
								[108.888459, 30.688573],
								[108.886971, 30.688322],
								[108.885927, 30.687124],
								[108.884356, 30.687345],
								[108.883974, 30.68963],
								[108.882961, 30.692524],
								[108.88354, 30.695657],
								[108.881153, 30.696001],
								[108.879221, 30.69527],
								[108.877619, 30.695104],
								[108.876451, 30.694158],
								[108.877009, 30.692548],
								[108.875345, 30.691479],
								[108.873268, 30.691989],
								[108.872328, 30.690459],
								[108.871284, 30.68979],
								[108.870137, 30.689931],
								[108.867884, 30.689218],
								[108.866841, 30.690042],
								[108.86527, 30.689145],
								[108.863575, 30.689366],
								[108.862738, 30.688549],
								[108.861188, 30.688174],
								[108.861281, 30.686829],
								[108.860485, 30.686503],
								[108.857468, 30.687154],
								[108.854616, 30.687302],
								[108.853468, 30.684383],
								[108.849738, 30.684125],
								[108.848953, 30.683265],
								[108.849046, 30.682159],
								[108.846968, 30.682282],
								[108.844809, 30.681354],
								[108.840706, 30.680537],
								[108.839642, 30.680009],
								[108.837895, 30.680156],
								[108.836077, 30.679603],
								[108.835994, 30.678448],
								[108.834309, 30.678934],
								[108.832646, 30.678166],
								[108.830197, 30.678485],
								[108.828708, 30.679431],
								[108.827334, 30.682092],
								[108.824968, 30.688457],
								[108.823335, 30.691688],
								[108.822281, 30.693083],
								[108.820927, 30.693912],
								[108.818085, 30.693771],
								[108.801169, 30.689409],
								[108.799732, 30.688899],
								[108.794906, 30.686614],
								[108.785875, 30.683499],
								[108.781173, 30.683019],
								[108.780119, 30.683867],
								[108.77892, 30.686214],
								[108.780573, 30.690165],
								[108.779664, 30.694403],
								[108.77985, 30.695724],
								[108.781028, 30.697039],
								[108.783942, 30.699017],
								[108.790597, 30.704367],
								[108.792581, 30.70656],
								[108.792592, 30.707088],
								[108.790928, 30.708999],
								[108.792488, 30.712524],
								[108.791889, 30.713722],
								[108.78977, 30.714275],
								[108.771056, 30.713323],
								[108.765889, 30.712217],
								[108.764576, 30.71226],
								[108.763429, 30.713046],
								[108.763109, 30.714139],
								[108.764173, 30.717751],
								[108.766591, 30.720564],
								[108.767139, 30.722504],
								[108.766974, 30.723426],
								[108.765, 30.723855],
								[108.762623, 30.728124],
								[108.762375, 30.72937],
								[108.763502, 30.73077],
								[108.76685, 30.732686],
								[108.768565, 30.735566],
								[108.768679, 30.737752],
								[108.768121, 30.739496],
								[108.767119, 30.740841],
								[108.764669, 30.742069],
								[108.761187, 30.742001],
								[108.758386, 30.740024],
								[108.756072, 30.739674],
								[108.754987, 30.740043],
								[108.752155, 30.742462],
								[108.749851, 30.745532],
								[108.748218, 30.749578],
								[108.747773, 30.754268],
								[108.746275, 30.75859],
								[108.745231, 30.760567],
								[108.744456, 30.764711],
								[108.742648, 30.76678],
								[108.740343, 30.774581],
								[108.740364, 30.777442],
								[108.741366, 30.778774],
								[108.745159, 30.780541],
								[108.747226, 30.782113],
								[108.746998, 30.78404],
								[108.745686, 30.784997],
								[108.742141, 30.786335],
								[108.740478, 30.787937],
								[108.737915, 30.798228],
								[108.737905, 30.799536],
								[108.739289, 30.804119],
								[108.739341, 30.806206],
								[108.738545, 30.80804],
								[108.7359, 30.811384],
								[108.733389, 30.814066],
								[108.731704, 30.814937],
								[108.729772, 30.815317],
								[108.725886, 30.815372],
								[108.719521, 30.815194],
								[108.720037, 30.816649],
								[108.719004, 30.81871],
								[108.717278, 30.819176],
								[108.715966, 30.818728],
								[108.715749, 30.81609],
								[108.71516, 30.81509],
								[108.704815, 30.811826],
								[108.701912, 30.811366],
								[108.699969, 30.811838],
								[108.698553, 30.813728],
								[108.697964, 30.816182],
								[108.697261, 30.817612],
								[108.698708, 30.820305],
								[108.698501, 30.82287],
								[108.696486, 30.826066],
								[108.695164, 30.827514],
								[108.690968, 30.83098],
								[108.687837, 30.83447],
								[108.685791, 30.83536],
								[108.685088, 30.836862],
								[108.686535, 30.84023],
								[108.686959, 30.842769],
								[108.686411, 30.844689],
								[108.685067, 30.845756],
								[108.679549, 30.848038],
								[108.675881, 30.851362],
								[108.671954, 30.852123],
								[108.6709, 30.854141],
								[108.670404, 30.857777],
								[108.669753, 30.860421],
								[108.66596, 30.867957],
								[108.664875, 30.869355],
								[108.66285, 30.870827],
								[108.659873, 30.871931],
								[108.658799, 30.873721],
								[108.659574, 30.877737],
								[108.655037, 30.887572],
								[108.653022, 30.891036],
								[108.651927, 30.891299],
								[108.650656, 30.890367],
								[108.64771, 30.890079],
								[108.641469, 30.889184],
								[108.638627, 30.888209],
								[108.634824, 30.885285],
								[108.634338, 30.88401],
								[108.636343, 30.882759],
								[108.635568, 30.880907],
								[108.633067, 30.878577],
								[108.627384, 30.87534],
								[108.625772, 30.875187],
								[108.6248, 30.876008],
								[108.624118, 30.877578],
								[108.623002, 30.883262],
								[108.621607, 30.888565],
								[108.62169, 30.889687],
								[108.623188, 30.89361],
								[108.623023, 30.90001],
								[108.622237, 30.903609],
								[108.622547, 30.905987],
								[108.625131, 30.908862],
								[108.625193, 30.909616],
								[108.623105, 30.91151],
								[108.623012, 30.912827],
								[108.624697, 30.915555],
								[108.627342, 30.917228],
								[108.628179, 30.918258],
								[108.628055, 30.919526],
								[108.627074, 30.920997],
								[108.623767, 30.924564],
								[108.620057, 30.926268],
								[108.619127, 30.927481],
								[108.619509, 30.933511],
								[108.618858, 30.934719],
								[108.612792, 30.938481],
								[108.611221, 30.939137],
								[108.609661, 30.938892],
								[108.608576, 30.938089],
								[108.603109, 30.931269],
								[108.598862, 30.925637],
								[108.59662, 30.923081],
								[108.593333, 30.92025],
								[108.589851, 30.918062],
								[108.585159, 30.916039],
								[108.581946, 30.915083],
								[108.575311, 30.913637],
								[108.57, 30.912766],
								[108.566424, 30.912392],
								[108.559283, 30.912123],
								[108.555914, 30.912613],
								[108.554344, 30.913502],
								[108.552618, 30.915414],
								[108.550985, 30.919618],
								[108.550189, 30.923155],
								[108.546666, 30.931434],
								[108.545643, 30.933266],
								[108.545684, 30.934008],
								[108.54743, 30.936612],
								[108.547916, 30.939161],
								[108.547606, 30.940393],
								[108.54586, 30.942066],
								[108.543741, 30.943113],
								[108.542036, 30.944676],
								[108.53999, 30.948499],
								[108.538647, 30.952518],
								[108.538605, 30.956072],
								[108.537551, 30.958118],
								[108.535195, 30.958632],
								[108.532147, 30.96093],
								[108.529253, 30.963876],
								[108.527424, 30.968458],
								[108.523611, 30.973169],
								[108.523332, 30.975399],
								[108.523838, 30.976464],
								[108.525409, 30.977279],
								[108.531258, 30.978032],
								[108.533469, 30.980501],
								[108.533893, 30.982026],
								[108.532581, 30.985756],
								[108.532591, 30.988059],
								[108.533924, 30.989657],
								[108.537468, 30.991096],
								[108.539329, 30.992646],
								[108.539287, 30.994226],
								[108.538347, 30.995193],
								[108.535577, 30.996136],
								[108.533903, 30.99624],
								[108.530875, 30.995665],
								[108.527031, 30.994219],
								[108.521813, 30.991941],
								[108.516656, 30.990551],
								[108.515406, 30.990735],
								[108.51338, 30.992352],
								[108.511623, 30.992848],
								[108.506642, 30.992621],
								[108.502953, 30.989871],
								[108.501538, 30.987789],
								[108.501114, 30.986515],
								[108.501362, 30.984463],
								[108.502519, 30.981946],
								[108.504131, 30.979839],
								[108.5041, 30.977304],
								[108.502571, 30.976764],
								[108.500752, 30.975509],
								[108.498665, 30.974523],
								[108.496629, 30.972832],
								[108.492836, 30.973242],
								[108.49047, 30.974351],
								[108.488734, 30.976617],
								[108.487711, 30.977218],
								[108.486398, 30.977022],
								[108.483588, 30.975448],
								[108.478462, 30.973978],
								[108.471828, 30.971852],
								[108.469337, 30.971889],
								[108.468252, 30.972256],
								[108.465524, 30.971938],
								[108.463385, 30.971092],
								[108.462579, 30.970339],
								[108.461669, 30.968213],
								[108.460533, 30.967417],
								[108.456234, 30.967962],
								[108.455438, 30.968538],
								[108.454746, 30.970247],
								[108.454715, 30.974878],
								[108.454095, 30.978026],
								[108.454301, 30.980654],
								[108.452886, 30.983257],
								[108.452731, 30.985058],
								[108.453278, 30.988738],
								[108.455149, 30.992168],
								[108.455603, 30.994728],
								[108.45363, 30.997912],
								[108.450695, 30.999578],
								[108.447285, 30.998586],
								[108.444598, 30.998665],
								[108.443626, 30.99918],
								[108.441529, 31.001592],
								[108.44034, 31.002554],
								[108.437509, 31.002842],
								[108.434357, 31.001133],
								[108.4329, 30.99951],
								[108.429893, 30.99937],
								[108.426513, 30.998218],
								[108.4199, 30.998831],
								[108.414991, 30.998898],
								[108.413317, 30.997312],
								[108.410878, 30.997183],
								[108.405308, 30.995065],
								[108.400358, 30.994317],
								[108.397465, 30.992823],
								[108.392329, 30.989032],
								[108.389239, 30.986307],
								[108.387875, 30.984825],
								[108.386067, 30.981309],
								[108.384031, 30.978982],
								[108.380993, 30.976446],
								[108.379009, 30.97549],
								[108.374968, 30.971607],
								[108.372788, 30.969775],
								[108.370111, 30.96814],
								[108.364417, 30.965298],
								[108.358537, 30.963337],
								[108.356398, 30.961199],
								[108.35521, 30.960427],
								[108.35397, 30.960354],
								[108.351376, 30.960893],
								[108.348286, 30.962345],
								[108.346653, 30.962798],
								[108.343997, 30.962553],
								[108.339667, 30.963123],
								[108.340546, 30.960777],
								[108.341879, 30.954748],
								[108.343698, 30.951158],
								[108.346529, 30.94413],
								[108.349009, 30.939161],
								[108.351593, 30.937758],
								[108.356088, 30.936483],
								[108.357431, 30.935754],
								[108.360842, 30.932978],
								[108.362722, 30.930282],
								[108.362381, 30.927659],
								[108.361007, 30.927359],
								[108.360098, 30.929062],
								[108.359075, 30.929173],
								[108.355396, 30.928064],
								[108.350921, 30.926997],
								[108.348865, 30.9259],
								[108.34749, 30.923774],
								[108.346291, 30.921236],
								[108.34284, 30.91971],
								[108.336619, 30.918246],
								[108.333725, 30.916658],
								[108.330005, 30.913992],
								[108.327029, 30.912233],
								[108.319268, 30.908954],
								[108.311373, 30.906398],
								[108.30478, 30.903045],
								[108.300285, 30.901028],
								[108.299138, 30.900096],
								[108.296172, 30.895903],
								[108.292018, 30.892979],
								[108.27259, 30.882673],
								[108.268384, 30.881661],
								[108.266121, 30.880055],
								[108.262391, 30.876229],
								[108.260055, 30.872795],
								[108.258639, 30.872213],
								[108.255405, 30.872703],
								[108.251127, 30.87507],
								[108.24969, 30.876125],
								[108.246063, 30.879454],
								[108.24381, 30.882268],
								[108.242891, 30.884696],
								[108.243087, 30.885959],
								[108.24473, 30.888516],
								[108.245102, 30.889779],
								[108.244296, 30.891128],
								[108.241382, 30.889233],
								[108.235016, 30.886266],
								[108.231368, 30.884108],
								[108.228227, 30.881281],
								[108.228134, 30.879932],
								[108.230635, 30.878154],
								[108.231182, 30.876125],
								[108.228743, 30.872605],
								[108.226945, 30.865431],
								[108.225468, 30.861377],
								[108.225922, 30.86004],
								[108.228227, 30.857593],
								[108.229963, 30.856164],
								[108.230025, 30.855355],
								[108.22865, 30.85376],
								[108.22677, 30.853208],
								[108.222967, 30.852914],
								[108.219918, 30.853043],
								[108.216725, 30.852037],
								[108.210339, 30.847443],
								[108.204893, 30.842027],
								[108.200119, 30.839187],
								[108.199168, 30.837967],
								[108.197266, 30.833654],
								[108.195665, 30.832317],
								[108.193381, 30.83101],
								[108.188379, 30.828986],
								[108.185475, 30.826919],
								[108.182179, 30.824201],
								[108.179575, 30.824207],
								[108.17633, 30.825551],
								[108.174284, 30.826857],
								[108.172589, 30.827299],
								[108.167102, 30.827189],
								[108.165893, 30.828906],
								[108.163309, 30.830587],
								[108.160736, 30.830532],
								[108.159703, 30.830882],
								[108.157481, 30.834771],
								[108.156799, 30.834857],
								[108.155084, 30.83371],
								[108.153306, 30.831777],
								[108.1522, 30.831274],
								[108.144791, 30.831851],
								[108.138983, 30.831667],
								[108.135707, 30.832427],
								[108.133486, 30.831814],
								[108.131708, 30.831986],
								[108.127378, 30.836022],
								[108.126944, 30.837089],
								[108.126851, 30.839445],
								[108.126045, 30.840856],
								[108.124413, 30.841469],
								[108.122656, 30.841083],
								[108.122366, 30.839519],
								[108.123927, 30.837267],
								[108.123121, 30.835605],
								[108.122862, 30.833495],
								[108.121219, 30.832556],
								[108.112188, 30.829882],
								[108.108312, 30.829299],
								[108.10637, 30.829937],
								[108.105036, 30.831378],
								[108.104757, 30.834219],
								[108.105564, 30.839427],
								[108.105295, 30.84134],
								[108.103424, 30.844297],
								[108.101223, 30.846278],
								[108.096635, 30.847823],
								[108.095343, 30.849087],
								[108.094527, 30.850884],
								[108.09525, 30.854834],
								[108.094796, 30.859341],
								[108.093669, 30.863401],
								[108.090672, 30.869539],
								[108.090135, 30.871538],
								[108.091664, 30.872881],
								[108.097803, 30.87236],
								[108.098939, 30.872765],
								[108.1008, 30.874433],
								[108.101719, 30.876039],
								[108.101647, 30.878001],
								[108.099994, 30.879589],
								[108.097379, 30.880723],
								[108.094145, 30.880963],
								[108.091706, 30.882134],
								[108.090652, 30.883513],
								[108.090848, 30.885089],
								[108.090083, 30.887694],
								[108.089143, 30.888798],
								[108.088192, 30.889019],
								[108.086198, 30.888185],
								[108.081816, 30.885702],
								[108.08038, 30.885334],
								[108.078943, 30.886143],
								[108.077156, 30.889344],
								[108.074345, 30.891508],
								[108.071761, 30.892703],
								[108.07048, 30.892673],
								[108.069684, 30.891557],
								[108.069137, 30.888265],
								[108.068713, 30.887805],
								[108.065737, 30.886848],
								[108.063195, 30.886861],
								[108.058751, 30.885162],
								[108.056199, 30.883544],
								[108.053646, 30.882324],
								[108.051807, 30.882244],
								[108.049854, 30.880546],
								[108.047931, 30.876413],
								[108.046412, 30.87664],
								[108.044211, 30.877664],
								[108.041101, 30.876523],
								[108.040884, 30.875739],
								[108.041659, 30.872777],
								[108.040946, 30.870005],
								[108.03985, 30.869153],
								[108.038569, 30.869184],
								[108.036295, 30.870116],
								[108.033454, 30.87507],
								[108.030787, 30.878651],
								[108.030126, 30.881073],
								[108.030095, 30.882967],
								[108.029444, 30.884488],
								[108.0253, 30.888222],
								[108.021322, 30.89502],
								[108.016847, 30.900194],
								[108.014832, 30.901518],
								[108.012931, 30.902119],
								[108.012083, 30.90283],
								[108.010895, 30.905337],
								[108.009386, 30.90766],
								[108.00487, 30.910554],
								[108.001708, 30.91192],
								[108.00084, 30.91192],
								[107.999104, 30.910885],
								[107.995983, 30.909505],
								[107.994722, 30.908684],
								[107.994578, 30.90652],
								[107.993834, 30.904289],
								[107.991632, 30.902389],
								[107.986972, 30.901923],
								[107.983314, 30.9002],
								[107.98072, 30.8983],
								[107.978322, 30.896179],
								[107.970996, 30.892384],
								[107.968939, 30.890778],
								[107.965395, 30.888822],
								[107.96307, 30.887958],
								[107.958946, 30.884862],
								[107.955949, 30.882967],
								[107.955226, 30.881018],
								[107.956146, 30.87672],
								[107.955743, 30.874089],
								[107.955288, 30.87328],
								[107.949232, 30.869325],
								[107.942112, 30.865075],
								[107.937059, 30.862665],
								[107.933938, 30.861972],
								[107.932078, 30.861114],
								[107.929484, 30.859335],
								[107.923635, 30.854693],
								[107.92224, 30.853895],
								[107.919926, 30.851657],
								[107.917208, 30.849823],
								[107.913477, 30.846327],
								[107.909437, 30.843518],
								[107.902678, 30.839114],
								[107.896519, 30.83493],
								[107.894256, 30.833213],
								[107.891538, 30.830237],
								[107.888221, 30.824054],
								[107.885049, 30.820275],
								[107.87613, 30.813274],
								[107.878559, 30.811574],
								[107.883798, 30.807157],
								[107.888087, 30.802518],
								[107.89035, 30.798732],
								[107.893729, 30.794951],
								[107.894721, 30.793233],
								[107.897697, 30.789281],
								[107.899485, 30.785611],
								[107.902275, 30.781032],
								[107.903526, 30.77994],
								[107.905055, 30.779369],
								[107.905851, 30.776674],
								[107.903598, 30.773182],
								[107.903732, 30.769346],
								[107.904394, 30.768719],
								[107.908238, 30.762931],
								[107.910759, 30.761157],
								[107.916185, 30.759382],
								[107.918644, 30.758296],
								[107.921558, 30.756116],
								[107.924813, 30.753311],
								[107.92686, 30.750339],
								[107.929712, 30.748104],
								[107.931965, 30.745759],
								[107.934889, 30.745759],
								[107.935819, 30.744844],
								[107.936604, 30.742302],
								[107.938826, 30.740128],
								[107.939798, 30.737181],
								[107.941751, 30.737163],
								[107.943507, 30.736211],
								[107.94576, 30.733859],
								[107.948478, 30.731458],
								[107.950545, 30.727909],
								[107.956177, 30.722424],
								[107.959101, 30.719274],
								[107.96062, 30.718482],
								[107.972122, 30.716725],
								[107.977103, 30.716621],
								[107.984678, 30.715663],
								[107.987799, 30.714981],
								[107.992841, 30.713507],
								[107.998019, 30.711683],
								[108.003268, 30.710172],
								[108.011411, 30.709815],
								[108.025466, 30.712088],
								[108.035179, 30.715368],
								[108.038476, 30.717063],
								[108.04479, 30.720705],
								[108.047528, 30.72369],
								[108.049761, 30.723733],
								[108.050277, 30.723168],
								[108.053315, 30.723088],
								[108.054648, 30.722351],
								[108.057924, 30.723186],
								[108.063215, 30.723923],
								[108.067597, 30.724212],
								[108.069788, 30.723935],
								[108.072412, 30.723094],
								[108.074903, 30.723125],
								[108.075947, 30.721497],
								[108.085691, 30.715859],
								[108.086901, 30.7146],
								[108.086684, 30.713709],
								[108.084627, 30.711781],
								[108.08068, 30.708642],
								[108.07976, 30.70675],
								[108.079222, 30.7039],
								[108.078685, 30.702629],
								[108.075223, 30.699932],
								[108.074593, 30.697887],
								[108.074831, 30.696289],
								[108.077486, 30.694422],
								[108.077931, 30.693746],
								[108.077838, 30.688475],
								[108.077042, 30.687111],
								[108.080525, 30.682534],
								[108.080142, 30.679898],
								[108.082054, 30.678885],
								[108.082178, 30.677637],
								[108.080008, 30.675462],
								[108.079729, 30.673508],
								[108.081589, 30.67105],
								[108.081672, 30.668605],
								[108.079873, 30.666135],
								[108.07977, 30.664801],
								[108.077342, 30.664144],
								[108.072423, 30.661532],
								[108.070015, 30.660985],
								[108.061107, 30.660334],
								[108.055392, 30.660045],
								[108.051373, 30.660733],
								[108.046123, 30.662466],
								[108.042578, 30.662491],
								[108.039127, 30.660702],
								[108.036099, 30.657961],
								[108.035582, 30.657181],
								[108.03086, 30.653838],
								[108.028173, 30.65176],
								[108.026871, 30.649837],
								[108.025672, 30.64886],
								[108.02529, 30.645688],
								[108.02561, 30.645012],
								[108.025321, 30.640968],
								[108.024101, 30.637157],
								[108.023078, 30.636186],
								[108.018139, 30.635153],
								[108.01787, 30.632331],
								[108.01693, 30.629565],
								[108.01786, 30.626233],
								[108.023419, 30.609953],
								[108.024773, 30.60402],
								[108.025238, 30.602882],
								[108.029816, 30.598473],
								[108.033712, 30.592435],
								[108.033681, 30.590147],
								[108.033278, 30.589403],
								[108.030116, 30.588775],
								[108.028028, 30.587435],
								[108.02776, 30.58578],
								[108.028028, 30.583862],
								[108.030829, 30.577908],
								[108.034787, 30.574187]
							]
						]
					]
				}
			}
		]
	}

	return geojson
}

export default {
	maps,
}
